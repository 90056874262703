import { preBudget2020 } from './calculatorData'

import {
    payOptions,
    DAILY,
    WEEKLY,
    FORTNIGHTLY,
    MONTHLY,
    CALCULATION_OBJECT,
    FREQUENCY,
    HOURLY_FREQUENCY,
    OVERTIME_FREQUENCY,
    PRORATA_LIMITS,
    OVERTIME_TYPE_HOURLY,
    FTB_CATEGORIES,
    FTB_A,
    FTB_A_SUPPLEMENT,
    FTB_B,
    FTB_B_SUPPLEMENT,
} from './calculatorConstants'

import {
    ignoreCents,
    roundToNearestCent,
    roundToNearestDollar,
    getTaxData,
    isUndefined,
} from './calculatorUtils';

import { formatMoney } from '../utils/utils';


import { SCALE2, SCALE6 } from '../constants';

//-----------------------------------------------------
// Constants
//-----------------------------------------------------

const state = () => Calculator.state;
// const day2Year = 365;

const WEEK_2_YEAR = 52 // 52.143 
const FORTNIGHT_2_YEAR = 26 //26.0893 


let week2Year = WEEK_2_YEAR
// const week2YearExtra = 53;
let fortnight2Year = FORTNIGHT_2_YEAR
// const fortnight2YearExtra = 27;
let day2Week = 5;
let month2Year = 12;
let day2Year = week2Year * day2Week;
let week2Month = week2Year / month2Year; //4.33
let week2Fortnight = 2;

// used to normalise casual ratios
// let daysPerWeek = 5;

let ORDINARY_HOURS_PER_WEEK = 38


//-----------------------------------------------------
// Tax Data
//-----------------------------------------------------
let useLegacyTaxData = false;

const TaxData = () => {
    if (useLegacyTaxData) {
        return preBudget2020;
    }
    return Calculator.taxData;
}



//-----------------------------------------------------
// Calculator
//-----------------------------------------------------
export const Calculator = {
    state: {},
    taxData: {},

    setState: (obj) => {
        Calculator.state = obj;
        Calculator.taxData = getTaxData(obj.year)
        return Calculator;
    },

    isDebug: false,


    ///////////////////////////////////////////
    // RESET
    ///////////////////////////////////////////
    reset: () => {
        // zero(state().YTD);
        zero(state().allowance);
        zero(state().baseSalary);
        zero(state().overtimeSalary);
        zero(state().overtimeSG);
        zero(state().deductions);
        zero(state().salarySacraficeIncome);
        zero(state().novatedLease);
        zero(state().superannuation);
        zero(state().superannuationReportable);
        zero(state().superannuationSacrafice);
        zero(state().totalSuperannuation);
        zero(state().fringeBenefitsAnnual)

        zero(state().help);
        zero(state().sfss);
        zero(state().medicare);
        zero(state().medicareAdjustment);
        zero(state().offsets)
        zero(state().extraWithholdingTax);

        state().salaryAnnual = 0;
        state().familyIncome = 0;
        state().otherTaxesAndLevies = 0;
        state().lito = 0;
        state().lamito = 0;
        state().mito = 0;
        state().mawto = 0;
        state().sapto = 0;
        state().spouseSuperTaxOffset = 0;
        state().superannuationConcessional = 0;
        state().superannuationConcessionalTax = 0;
        //state().superannationTaxableNonConcessional = 0;
        state().superannuationCoContribution = 0;
        state().superannuationCoContributionNonConcessional = 0;
        state().superannuationConcessionalSavings = 0;
        state().superannuationConcessionalNetSavings = 0;
        state().salarySacraficeIncomeSavings = 0;
        state().superannuationSacraficeSavings = 0;
        state().superannuationSacraficeNetSavings = 0;
        state().superannuationExcess = 0;
        state().superannuationExcessTax = 0;
        state().superannuationExcessConcessionalContributionsOffset = 0
        state().concessionalAdditionalSuper = 0;
        //state().reportableFringeBenefits = 0;

        state().taxableDeductionsTax = 0;
        state().capitalGainsTax = 0;
        state().otherIncomeTax = 0;

        state().bonusIncomeTax = 0;

        state().businessIncomeTax = 0;

        state().adjustedTaxableIncome = 0; // annual not including annual deductions
        state().adjustedAnnualTaxableIncome = 0; // annual including deductions
        state().adjustedWeelkyTaxableIncome = 0; // weekly not including deducitons

        state().incomeTaxWithheldMonthlyLegacy = 0;

        state().medicareFamilyAdjustment = false;
        // state().warnings.superannuationCapped = false;
        // state().warnings.superannuation = [];

        state().ftb_a = 0;
        state().ftb_a_supplement = 0;
        state().ftb_b = 0;
        state().ftb_b_supplement = 0;
        zero(state().ftb)

        state().warnings = {
            superannuation: [],
            nonConcessionalCap: false,// over the limit
            superannuationGuarantee: false, // under the guarantee
            medicareSurcharge: false, // Over the limit if not private healthcare
            division293: false, // additional tax on super outside fund
            extraPayment: state().warnings.extraPayment,
            maxSalarySacrafice: false,
            superannuationCapped: false,
            maximunContributionsCapActive: false,
        };

        // reset ratios
        // week2Year = WEEK_2_YEAR;
        // fortnight2Year = FORTNIGHT_2_YEAR;

        week2Year = state().weeksPerYear;
        fortnight2Year = state().fortnightsPerYear;
        month2Year = 12;
        day2Year = week2Year * day2Week;
        week2Month = week2Year / month2Year; //4.33
        week2Fortnight = 2;



        return Calculator;
    },




    // ///////////////////////////////////////////
    // // YTD
    // ///////////////////////////////////////////
    // calculateYTD: () => {

    //     const { year, payDay, payOption, warnings, adjustPayDay, casual, ytdDate, ytd } = state();
    //     if(ytd){
    //     let payDate = new Date(payDay);
    //     const startFTY = new Date(Number(year) - 1, 6, 1);
    //     const endFTY = new Date((year), 6, 1);

    //     // default
    //     let checkPayments = {};
    //     let extraPayment = false;

    //     if (adjustPayDay) {
    //       // make sure the date is in range
    //       payDate = resetDate(payDate, startFTY, endFTY);
    //     } else {
    //       // safe start date
    //       payDate = new Date(Number(year) - 1, 6, 6);
    //     }

    //     console.log("checkPayDay......", ytd, ytdDate)
    //     checkPayments = calculatePayments(payDate, year, payOption, adjustPayDay, casual, ytd, ytdDate);
    //     extraPayment = checkPayments.payments.f > InitialState.payments.f || checkPayments.payments.w > InitialState.payments.w;
    //     //return { payDay: date2str(payDate), ...checkPayments, warnings: { ...warnings, extraPayment } };


    //     //return { payments: { d: days, w: weeks, f: fortnights, m: months, a: 1 }, YTD: { d: daysYTD, w: weeksYTD, f: fortnightsYTD, m: monthsYTD, a: 1 } };
    //     //state() = { ...state(), ...checkPayments}
    //     state().payments = checkPayments.payments;
    //     state().YTD = checkPayments.YTD;


    //     state().payDay = date2str(payDate);
    //     state().warnings = { ...warnings, extraPayment } ;


    //     }else{
    //         state().YTD = NO_YTD
    //     }
    //     return Calculator;
    //   },


    ///////////////////////////////////////////
    // Income
    ///////////////////////////////////////////
    getIncome: () => {

        const payCycle = payOptions[state().payOption].title.toLowerCase();


        // check if there are additional weekly or fortnightly payments
        if (payCycle === "fortnightly" && state().payments.f > 26) {
            fortnight2Year = state().payments.f;
        } else {
            fortnight2Year = state().fortnightsPerYear;
        }

        if (payCycle === "weekly" && state().payments.w > 52) {
            week2Year = state().payments.w;
        } else {
            week2Year = state().weeksPerYear;
        }

        // capture data in form field    
        //export const FREQUENCY = ["Week", "Fortnight", "Month", "Year"];
        switch (payCycle) {
            case "annually":

                state().baseSalary.a = getInputIncome();
                //derived
                state().baseSalary.m = state().baseSalary.a / month2Year;
                state().baseSalary.f = state().baseSalary.a / fortnight2Year;
                state().baseSalary.w = state().baseSalary.a / week2Year;
                state().baseSalary.d = state().baseSalary.w / day2Week;

                break;

            case "monthly":
                state().baseSalary.m = getInputIncome();
                //derived
                state().baseSalary.a = state().baseSalary.m * month2Year;
                state().baseSalary.f = state().baseSalary.a / fortnight2Year;
                state().baseSalary.w = state().baseSalary.a / week2Year;
                state().baseSalary.d = state().baseSalary.w / day2Week;

                break;
            case "fortnightly":

                state().baseSalary.f = getInputIncome();
                state().baseSalary.w = state().baseSalary.f / week2Fortnight;
                state().baseSalary.d = state().baseSalary.w / day2Week;
                state().baseSalary.m = state().baseSalary.w * week2Month;
                //derived
                state().baseSalary.a = state().baseSalary.f * fortnight2Year;

                break;
            case "weekly":
                state().baseSalary.w = getInputIncome();
                state().baseSalary.d = state().baseSalary.w / day2Week;
                state().baseSalary.f = state().baseSalary.w * week2Fortnight;
                state().baseSalary.m = state().baseSalary.w * week2Month;
                // derived
                state().baseSalary.a = state().baseSalary.w * week2Year;


                break;
            case "daily":
                //casual
                if (state().casual.frequency === FREQUENCY[0]) {
                    // Days per week
                    state().baseSalary.d = getInputIncome();
                    state().baseSalary.w = getInputIncome() * state().casual.days;
                    state().baseSalary.f = state().baseSalary.w * week2Fortnight;
                    state().baseSalary.m = state().baseSalary.w * week2Month;

                    state().baseSalary.a = state().baseSalary.w * week2Year;
                    // adjust annual amount
                    state().baseSalary.a = Number(state().casual.annual) * state().baseSalary.w;

                    day2Year = week2Year * state().casual.days;
                }

                if (state().casual.frequency === FREQUENCY[1]) {
                    // Days per fortnight
                    state().baseSalary.d = getInputIncome();
                    state().baseSalary.f = getInputIncome() * state().casual.days;
                    state().baseSalary.w = state().baseSalary.f / week2Fortnight;
                    state().baseSalary.m = state().baseSalary.w * week2Month;

                    state().baseSalary.a = state().baseSalary.f * fortnight2Year
                    // adjust annual amount
                    state().baseSalary.a = Number(state().casual.annual) * state().baseSalary.f;

                    day2Year = fortnight2Year * state().casual.days;
                }

                if (state().casual.frequency === FREQUENCY[2]) {

                    // Days per month
                    state().baseSalary.d = getInputIncome();
                    state().baseSalary.m = getInputIncome() * state().casual.days;
                    state().baseSalary.a = state().baseSalary.m * month2Year;
                    state().baseSalary.w = state().baseSalary.m / week2Month;
                    state().baseSalary.f = state().baseSalary.w * 2;

                    // adjust annual amount
                    state().baseSalary.a = Number(state().casual.annual) * state().baseSalary.m;
                    day2Year = month2Year * state().casual.days;
                }

                if (state().casual.frequency === FREQUENCY[3]) {
                    // Days per year
                    state().baseSalary.d = getInputIncome();
                    state().baseSalary.a = getInputIncome() * state().casual.days;
                    state().baseSalary.m = state().baseSalary.a / month2Year;
                    state().baseSalary.f = state().baseSalary.a / fortnight2Year;
                    state().baseSalary.w = state().baseSalary.a / week2Year;

                    day2Year = state().casual.days;
                }

                // unpaid leave
                if (!state().fulltime) {
                    state().baseSalary.a -= state().baseSalary.d * state().unpaidPublicHolidays
                    state().baseSalary.a -= state().baseSalary.d * state().unpaidLeave
                }

                break;

            case "hourly":
                //casual
                // state().baseSalary.d = getInputIncome() * Number(state().casual.hours);

                const HOURS_PER_DAY = state().casual.frequency === HOURLY_FREQUENCY[0];
                // const frequency = HOURS_PER_DAY ? state().casual.frequency_day  : state().casual.frequency;

                if (HOURS_PER_DAY) {
                    state().baseSalary.d = getInputIncome() * Number(state().casual.hours);


                    if (state().casual.frequency_day === FREQUENCY[0]) {
                        // Hours per week
                        state().baseSalary.w = state().baseSalary.d * Number(state().casual.days);
                        //state().baseSalary.d = state().baseSalary.w / day2Week;
                        state().baseSalary.f = state().baseSalary.w * week2Fortnight;
                        state().baseSalary.m = state().baseSalary.w * week2Month;
                        //state().baseSalary.a = state().baseSalary.w * week2Year;

                        // adjust annual amount
                        state().baseSalary.a = Number(state().casual.annual) * state().baseSalary.w;
                        day2Year = week2Year * state().casual.days;

                    }

                    if (state().casual.frequency_day === FREQUENCY[1]) {
                        // Hours per fortnight
                        state().baseSalary.f = state().baseSalary.d * state().casual.days
                        state().baseSalary.w = state().baseSalary.f / week2Fortnight;
                        //state().baseSalary.d = state().baseSalary.w / day2Week
                        state().baseSalary.m = state().baseSalary.w * week2Month;

                        // state().baseSalary.a = state().baseSalary.f * fortnight2Year;
                        // adjust annual amount
                        state().baseSalary.a = Number(state().casual.annual) * state().baseSalary.f;
                    }

                    if (state().casual.frequency_day === FREQUENCY[2]) {
                        // Hours per month
                        state().baseSalary.m = state().baseSalary.d * state().casual.days
                        state().baseSalary.a = state().baseSalary.m * month2Year;
                        state().baseSalary.f = state().baseSalary.a / fortnight2Year;
                        state().baseSalary.w = state().baseSalary.a / week2Year;
                        //state().baseSalary.d = state().baseSalary.w / day2Week

                        // adjust annual amount
                        state().baseSalary.a = Number(state().casual.annual) * state().baseSalary.m;
                    }

                    if (state().casual.frequency_day === FREQUENCY[3]) {
                        // Days per year
                        state().baseSalary.a = state().baseSalary.d * state().casual.days
                        state().baseSalary.m = state().baseSalary.a / month2Year;
                        state().baseSalary.f = state().baseSalary.a / fortnight2Year;
                        state().baseSalary.w = state().baseSalary.a / week2Year;
                        //state().baseSalary.d = state().baseSalary.w / day2Week
                    }

                } else {

                    if (state().casual.frequency === FREQUENCY[0]) {
                        // Hours per week

                        state().baseSalary.w = getInputIncome() * Number(state().casual.hours);
                        state().baseSalary.d = state().baseSalary.w / day2Week;
                        state().baseSalary.f = state().baseSalary.w * week2Fortnight;
                        state().baseSalary.m = state().baseSalary.w * week2Month;
                        //state().baseSalary.a = state().baseSalary.w * week2Year;

                        // adjust annual amount
                        state().baseSalary.a = Number(state().casual.annual) * state().baseSalary.w;

                    }

                    if (state().casual.frequency === FREQUENCY[1]) {
                        // Hours per fortnight
                        state().baseSalary.f = getInputIncome() * Number(state().casual.hours);
                        state().baseSalary.w = state().baseSalary.f / week2Fortnight;
                        state().baseSalary.d = state().baseSalary.w / day2Week;
                        state().baseSalary.m = state().baseSalary.w * week2Month;

                        // state().baseSalary.a = state().baseSalary.f * fortnight2Year;
                        // adjust annual amount
                        state().baseSalary.a = Number(state().casual.annual) * state().baseSalary.f;
                    }

                    if (state().casual.frequency === FREQUENCY[2]) {
                        // Hours per month
                        state().baseSalary.m = getInputIncome() * Number(state().casual.hours);
                        state().baseSalary.a = state().baseSalary.m * month2Year;
                        state().baseSalary.f = state().baseSalary.a / fortnight2Year;
                        state().baseSalary.w = state().baseSalary.a / week2Year;
                        state().baseSalary.d = state().baseSalary.w / day2Week

                        // adjust annual amount
                        state().baseSalary.a = Number(state().casual.annual) * state().baseSalary.m;
                    }

                    if (state().casual.frequency === FREQUENCY[3]) {
                        // Days per year
                        state().baseSalary.a = getInputIncome() * Number(state().casual.hours);
                        state().baseSalary.m = state().baseSalary.a / month2Year;
                        state().baseSalary.f = state().baseSalary.a / fortnight2Year;
                        state().baseSalary.w = state().baseSalary.a / week2Year;
                        state().baseSalary.d = state().baseSalary.w / day2Week

                    }

                }
                // unpaid leave leave
                if (!state().fulltime) {
                    state().baseSalary.a -= state().baseSalary.d * state().unpaidPublicHolidays
                    state().baseSalary.a -= state().baseSalary.d * state().unpaidLeave
                }
                break;

            default:
                break;
        }

        state().baseSalary.a = roundToNearestCent(state().baseSalary.a)
        state().baseSalary.m = roundToNearestCent(state().baseSalary.m)
        state().baseSalary.f = roundToNearestCent(state().baseSalary.f)
        state().baseSalary.w = roundToNearestCent(state().baseSalary.w)
        state().baseSalary.d = roundToNearestCent(state().baseSalary.d)

        // base income off base Salary
        state().income.a = state().baseSalary.a;
        state().income.m = state().baseSalary.m;
        state().income.f = state().baseSalary.f;
        state().income.w = state().baseSalary.w;
        state().income.d = state().baseSalary.d;

        if (Calculator.isDebug) console.log("Get Income -> Base Salary: ", state().baseSalary)

        // set ratios
        // Adjust payg to match ratios of annual to pay salary ratios

        //week2Year = state().baseSalary.a / state().baseSalary.w | week2Year;
        // day2Year = state().baseSalary.a / state().baseSalary.d | day2Year;
        // fortnight2Year = state().baseSalary.a / state().baseSalary.f | fortnight2Year;
        // month2Year = state().baseSalary.a / state().baseSalary.m | month2Year;
        // week2Month = week2Year / month2Year | week2Month;
        // week2Fortnight = 2;


        return Calculator;

    },


    ///////////////////////////////////////////
    // ProRata
    ///////////////////////////////////////////
    calculateProRata: () => {

        // only if fulltime
        const fulltime = payOptions[state().payOption].type === "fulltime";

        if (fulltime && state().adjustProRata) {
            state().proRataPercent = state().proRataAmount / PRORATA_LIMITS[state().proRataAmountFrequency][state().proRataFrequency];

            state().income.a *= state().proRataPercent;
            state().income.m *= state().proRataPercent;
            state().income.f *= state().proRataPercent;
            state().income.w *= state().proRataPercent;
            state().income.d *= state().proRataPercent;


            state().baseSalary.a *= state().proRataPercent;
            state().baseSalary.m *= state().proRataPercent;
            state().baseSalary.f *= state().proRataPercent;
            state().baseSalary.w *= state().proRataPercent;
            state().baseSalary.d *= state().proRataPercent;

        } else {
            if (!fulltime) {
                // how many cycles per year
                if (payOptions[state().payOption].type === "daily") {
                    switch (state().casual.frequency) {
                        case "Week":
                            // weeks per year
                            state().proRataPercent = state().casual.annual / week2Year;
                            break;
                        case "Fortnight":
                            // Fortnight per year
                            state().proRataPercent = state().casual.annual / fortnight2Year;
                            break;
                        case "Month":
                            state().proRataPercent = state().casual.annual / month2Year;
                            break;
                        case "Year":
                            // day per year
                            state().proRataPercent = state().casual.annual / day2Year;
                            break;
                        default:
                            state().proRataPercent = 1.0;
                            break;

                    }
                }

                if (payOptions[state().payOption].type === "hourly") {
                    switch (state().casual.frequency) {
                        case "Day":
                            // weeks per year
                            switch (state().casual.frequency_day) {
                                case "Week":
                                    state().proRataPercent = state().casual.annual / month2Year;
                                    break;
                                case "Fortnight":
                                    state().proRataPercent = state().casual.annual / month2Year;
                                    break;
                                case "Month":
                                    state().proRataPercent = state().casual.annual / month2Year;
                                    break;
                                default: break;
                            }
                            break;
                        case "Week":
                            // weeks per year
                            state().proRataPercent = state().casual.annual / week2Year;
                            break;
                        case "Fortnight":
                            // Fortnight per year
                            state().proRataPercent = state().casual.annual / fortnight2Year;
                            break;
                        case "Month":
                            state().proRataPercent = state().casual.annual / month2Year;
                            break;
                        case "Year":
                            // hours per year
                            state().proRataPercent = state().casual.hours / (ORDINARY_HOURS_PER_WEEK * week2Year);
                            break;
                        default:
                            state().proRataPercent = 1.0;
                            break;
                    }
                }
            } else {
                state().proRataPercent = 1;
            }
        }

        return Calculator;
    },


    ///////////////////////////////////////////
    // OVERTIME
    ///////////////////////////////////////////
    calculateOvertime: () => {
        let overtimePerDay = 0;
        let overtimePerWeek = 0;
        let overtimePerFortnight = 0;
        let overtimePerMonth = 0;
        let overtimePerYear = 0;

        state().overtime.map(ot => {
            if (!ot) return Calculator;
            let hourlyRate = 0;
            if (ot.type === OVERTIME_TYPE_HOURLY) {
                hourlyRate = ot.hourlyRate;
            } else {
                // refer back to the primary hourly rate 
                hourlyRate = state().salary * ot.loadingRate;
            }

            if (ot.frequency === OVERTIME_FREQUENCY[0]) {
                // hours per day
                overtimePerDay = ot.hours * hourlyRate;
                overtimePerWeek = overtimePerDay * day2Week;
                overtimePerFortnight = overtimePerWeek * week2Fortnight;
                overtimePerMonth = overtimePerWeek * week2Month;

                overtimePerYear = ot.annualFrequency * (ot.hours * hourlyRate); // (weeks per year)

            } else if (ot.frequency === OVERTIME_FREQUENCY[1]) {
                // hours per week
                overtimePerWeek = ot.hours * hourlyRate;
                overtimePerDay = overtimePerWeek / day2Week;
                overtimePerFortnight = overtimePerWeek * week2Fortnight;
                overtimePerMonth = overtimePerWeek * week2Month;

                overtimePerYear = ot.annualFrequency * (ot.hours * hourlyRate); // (weeks per year)

            } else if (ot.frequency === OVERTIME_FREQUENCY[2]) {
                // hours per ft
                overtimePerFortnight = (ot.hours * hourlyRate);
                overtimePerWeek = overtimePerFortnight / week2Fortnight
                overtimePerDay = overtimePerWeek / day2Week;
                overtimePerMonth = overtimePerWeek * week2Month;

                overtimePerYear = ot.annualFrequency * (ot.hours * hourlyRate);


            } else if (ot.frequency === OVERTIME_FREQUENCY[3]) {
                // hours per month
                overtimePerMonth = (ot.hours * hourlyRate);
                overtimePerWeek = overtimePerMonth / week2Month;
                overtimePerDay = overtimePerWeek / day2Week;
                overtimePerFortnight = overtimePerWeek * week2Fortnight

                overtimePerYear = ot.annualFrequency * (ot.hours * hourlyRate);

            } else if (ot.frequency === OVERTIME_FREQUENCY[4]) {
                // hours per year
                overtimePerYear = ot.annualFrequency * (ot.hours * hourlyRate);


                overtimePerWeek = overtimePerYear / week2Year;
                overtimePerDay = overtimePerWeek / day2Week;
                overtimePerFortnight = overtimePerWeek * week2Fortnight;
                overtimePerMonth = overtimePerWeek * week2Month;
            }

            // add overtime to total overtime
            state().overtimeSalary.d += overtimePerDay;
            state().overtimeSalary.w += overtimePerWeek;
            state().overtimeSalary.f += overtimePerFortnight
            state().overtimeSalary.m += overtimePerMonth
            state().overtimeSalary.a += overtimePerYear;

            // included in superGuarentee
            if (ot.super) {
                state().overtimeSG.d += overtimePerDay;
                state().overtimeSG.w += overtimePerWeek;
                state().overtimeSG.f += overtimePerFortnight
                state().overtimeSG.m += overtimePerMonth
                state().overtimeSG.a += overtimePerYear;
            }

            // return Calculator;
            return false;
        })

        // Add overtime per week to income
        // state().overtimeSalary.w = overtimePerWeek;
        // state().overtimeSalary.f = overtimePerWeek * week2Fortnight;
        // state().overtimeSalary.m = (overtimePerWeek * week2Year) / month2Year;

        // state().overtimeSalary.a = overtimePerYear;
        state().income.d += state().overtimeSalary.d;
        state().income.w += state().overtimeSalary.w;
        state().income.f += state().overtimeSalary.f;
        state().income.a += state().overtimeSalary.a;
        state().income.m += state().overtimeSalary.m;



        return Calculator;
    },

    calculateAnnualSalary: () => {

        return Calculator;
    },


    ///////////////////////////////////////////
    // Allowances
    ///////////////////////////////////////////
    calculateAllowances: () => {
        // Allowances
        if (state().adjustAllowanceIncome === true) {
            switch (Number(state().allowanceIncomeOption)) {
                case 0:
                    // annual
                    // add income to annual amount only
                    state().allowance.a = Number(state().allowanceIncome);
                    break;

                case 1:
                    // month
                    // state().allowance.m = Number(state().allowanceIncome);
                    state().allowance.a = month2Year * Number(state().allowanceIncome);
                    break;

                case 2:
                    // fortnight
                    // state().allowance.f = Number(state().allowanceIncome);
                    // state().allowance.m = (fortnight2Year * Number(state().allowanceIncome)) / month2Year;
                    state().allowance.a = fortnight2Year * Number(state().allowanceIncome);
                    break;

                case 3:
                    // week
                    // state().allowance.w = Number(state().allowanceIncome);
                    // state().allowance.f = week2Fortnight * Number(state().allowanceIncome);
                    // state().allowance.m = week2Month * Number(state().allowanceIncome);
                    state().allowance.a = week2Year * Number(state().allowanceIncome);
                    break;
                default:
                    break;
            }

            spreadAnnualAmounts(state().allowance);
            // add allowance to income
            if (state().allowanceIncomeTaxable) {
                state().income.w += state().allowance.w/day2Week;
                state().income.w += state().allowance.w;
                state().income.f += state().allowance.f;
                state().income.m += state().allowance.m;
                state().income.a += state().allowance.a;
            }
        }

        return Calculator;

    },


    ///////////////////////////////////////////
    //  NOVATED LEASE
    ///////////////////////////////////////////
    calculateNovatedLease: () => {
        let sacrifice = state().novatedLeaseAmount;
        // validate
        if (sacrifice < 0) { state().novatedLeaseAmount = 0 }
        if (isNaN(sacrifice)) { state().novatedLeaseAmount = 0; }

        // calculate
        if (sacrifice > 0) {

            switch (Number(state().novatedLeaseOption)) {
                case 0:
                    state().novatedLease.a = sacrifice;
                    break;
                case 1:
                    // month
                    state().novatedLease.a = sacrifice * month2Year;
                    break;
                case 2:
                    // fortnight
                    state().novatedLease.a = sacrifice * fortnight2Year;
                    break;
                case 3:
                    // week
                    state().novatedLease.a = sacrifice * week2Year;
                    break;
                case 4:
                    // percent
                    // reset if the value is > 100
                    if (sacrifice > 100) {
                        sacrifice = 100
                        state().novatedLeaseAmount = sacrifice;
                    }

                    state().novatedLease.a = state().income.a * (sacrifice / 100);

                    if (state().income.a > 0) {
                        state().novatedLeaseAmount = state().novatedLease.a * 100 / state().income.a
                    }
                    break;
                default:
                    break;
            }

            // spread the amounts
            spreadAnnualAmounts(state().novatedLease);
        }


        // if this is in addition to income, deduct it from baseSalary
        state().income.a -= Number(state().novatedLease.a);
        state().income.m -= Number(state().novatedLease.m);
        state().income.f -= Number(state().novatedLease.f);
        state().income.w -= Number(state().novatedLease.w);
        state().income.d -= Number(state().novatedLease.w)/day2Week;

        return Calculator;
    },




    ///////////////////////////////////////////
    //  Salary sacrafice from Income NOT SUPER!!
    ///////////////////////////////////////////
    calculateSalarySacraficeIncome: () => {
        let sacrifice = state().salarySacraficeIncomeAmount;
        // validate
        if (sacrifice < 0) { state().salarySacraficeIncomeAmount = 0 }
        if (isNaN(sacrifice)) { state().salarySacraficeIncomeAmount = 0; }

        // calculate
        if (sacrifice > 0) {

            switch (Number(state().salarySacraficeIncomeOption)) {
                case 0:
                    state().salarySacraficeIncome.a = sacrifice;
                    break;
                case 1:
                    // month
                    state().salarySacraficeIncome.a = sacrifice * month2Year;
                    break;
                case 2:
                    // fortnight
                    state().salarySacraficeIncome.a = sacrifice * fortnight2Year;
                    break;
                case 3:
                    // week
                    state().salarySacraficeIncome.a = sacrifice * week2Year;
                    break;
                case 4:
                    // percent
                    // reset if the value is > 100
                    if (sacrifice > 100) {
                        sacrifice = 100
                        state().salarySacraficeIncomeAmount = sacrifice;
                    }

                    state().salarySacraficeIncome.a = state().income.a * (sacrifice / 100);

                    if (state().income.a > 0) {
                        state().salarySacraficeIncomeAmount = state().salarySacraficeIncome.a * 100 / state().income.a
                    }
                    break;
                default:
                    break;
            }

            // spread the amounts
            spreadAnnualAmounts(state().salarySacraficeIncome);
        }


        // if this is in addition to income, deduct it from baseSalary
        state().income.a -= Number(state().salarySacraficeIncome.a);
        state().income.m -= Number(state().salarySacraficeIncome.m);
        state().income.f -= Number(state().salarySacraficeIncome.f);
        state().income.w -= Number(state().salarySacraficeIncome.w);
        state().income.d -= Number(state().salarySacraficeIncome.w)/day2Week;

        return Calculator;
    },



    ///////////////////////////////////////////
    // Superannuation Salary sacrafice Superannuation
    ///////////////////////////////////////////
    calculateSalarySacrafice: () => {
        //reset 
        // state().salaryScaracficeAmount = 0;
        let limit = Number.MAX_SAFE_INTEGER;
        if (TaxData().superannuation && TaxData().superannuation.concessionalCap !== undefined) {
            limit = TaxData().superannuation.concessionalCap - state().superannuation.a - state().superannuationReportable.a
        } else {
            console.log("No 'superannuation.concessionalCap' data")
        }

        if (limit < 0) limit = 0;

        // inline check to limit sacrafice super to concessional limit
        // const limitSalarySacrafice = false;//state().superannuationGuaranteeCap;
        const limitSalarySacrafice = (state().adjustSalaryScaracfice && state().salaryScaracficeMaximise)

        if (!limitSalarySacrafice) {
            limit = Number.MAX_SAFE_INTEGER;
        }

        // ensure salary sacrafice values are within the income range
        if (state().adjustSalaryScaracfice) {

            if (state().salaryScaracficeAmount <= 0) { state().salaryScaracficeAmount = 0 }
            if (isNaN(state().salaryScaracficeAmount)) { state().salaryScaracficeAmount = 0; }
            if (limitSalarySacrafice || state().salaryScaracficeAmount > limit) {
                state().salaryScaracficeAmount = limit;
            }

            switch (Number(state().salarySacraficeOption)) {
                case 0:
                    // year
                    if (limitSalarySacrafice || state().salaryScaracficeAmount > limit) {
                        state().salaryScaracficeAmount = limit;
                        state().superannuationSacrafice.a = limit
                    } else {
                        state().superannuationSacrafice.a = state().salaryScaracficeAmount;
                    }
                    break;
                case 1:
                    // month
                    //state().salaryScaracficeAmount = Math.min(state().salaryScaracficeAmount, state().income.m);
                    if (limitSalarySacrafice || state().salaryScaracficeAmount > limit) {
                        state().salaryScaracficeAmount = limit / month2Year;
                        state().superannuationSacrafice.a = limit
                    } else {
                        state().superannuationSacrafice.a = state().salaryScaracficeAmount * month2Year;
                        state().salaryScaracficeAmount = state().superannuationSacrafice.a / month2Year;
                    }
                    break;
                case 2:
                    // fortnight
                    //state().salaryScaracficeAmount = Math.min(state().salaryScaracficeAmount, state().income.f);
                    if (limitSalarySacrafice || state().salaryScaracficeAmount > limit) {
                        state().salaryScaracficeAmount = limit / fortnight2Year;
                        state().superannuationSacrafice.a = limit
                    } else {
                        state().superannuationSacrafice.a = state().salaryScaracficeAmount * fortnight2Year;
                        state().salaryScaracficeAmount = state().superannuationSacrafice.a / fortnight2Year;
                    }
                    break;
                case 3:
                    // week
                    //state().salaryScaracficeAmount = Math.min(state().salaryScaracficeAmount, state().income.w);
                    if (limitSalarySacrafice || state().salaryScaracficeAmount > limit) {
                        state().salaryScaracficeAmount = limit / week2Year;
                        state().superannuationSacrafice.a = limit
                    } else {
                        state().superannuationSacrafice.a = state().salaryScaracficeAmount * week2Year;
                        state().salaryScaracficeAmount = state().superannuationSacrafice.a / week2Year;
                    }
                    break;
                case 4:
                    // percent
                    // reset if the value is > 100
                    //state().salaryScaracficeAmount = state().salaryScaracficeAmount > 100 ? 0 : state().salaryScaracficeAmount;

                    if (limitSalarySacrafice || state().salaryScaracficeAmount > limit) {
                        // avoid div by zero
                        state().salaryScaracficeAmount = state().income.a > 0 ? (limit / state().income.a) * 100 : 0
                        state().superannuationSacrafice.a = limit
                    } else {
                        state().superannuationSacrafice.a = state().income.a * (state().salaryScaracficeAmount / 100);
                        state().salaryScaracficeAmount = state().income.a > 0 ? state().superannuationSacrafice.a * 100 / state().income.a : 0
                    }

                    break;
                default:
                    break;

            }

            // spread the amounts
            spreadAnnualAmounts(state().superannuationSacrafice);

            // -- The income.a has already been adjusted based on the pro rata rate --
            // adjust for a prorate if the salary sacrafice is not an annul amount
            //if (Number(state().salarySacraficeOption) !== 0) {
            //state().superannuationSacrafice.a = state().superannuationSacrafice.a * state().proRataPercent;
            //}

        } else {
            // reset
            state().superannuationSacrafice.a = 0;
            spreadAnnualAmounts(state().superannuationSacrafice);
        }


        // if this is in addition to income, deduct it from baseSalary
        if (state().adjustSalaryScaracfice) {
            state().income.a -= Number(state().superannuationSacrafice.a);
            state().income.m -= Number(state().superannuationSacrafice.m);
            state().income.f -= Number(state().superannuationSacrafice.f);
            state().income.w -= Number(state().superannuationSacrafice.w);
            state().income.d -= Number(state().superannuationSacrafice.w)/day2Week
        }

        state().warnings.maxSalarySacrafice = (state().salaryScaracficeAmount >= state().income.a);

        if (Calculator.isDebug) console.log("calculateSalarySacrafice:", state().superannuationSacrafice)

        return Calculator;
    },





    ///////////////////////////////////////////
    // FRINGE BENEFITS
    ///////////////////////////////////////////
    calculateFringeBenefits: () => {


        //export const FREQUENCY = ["Week", "Fortnight", "Month", "Year"];

        if (state().adjustFringeBenefits) {
            switch (Number(state().fringeBenefitsFrequency)) {
                case 0:
                    // annual
                    // add income to annual amount only
                    state().fringeBenefitsAnnual.a = Number(state().fringeBenefits);
                    break;

                case 1:
                    // month
                    //state().fringeBenefitsAnnual.m = Number(state().fringeBenefits);
                    state().fringeBenefitsAnnual.a = month2Year * Number(state().fringeBenefits);
                    break;

                case 2:
                    // fortnight
                    //state().fringeBenefitsAnnual.f = Number(state().fringeBenefits);
                    //state().fringeBenefitsAnnual.m = (fortnight2Year * Number(state().fringeBenefits)) / month2Year;
                    state().fringeBenefitsAnnual.a = fortnight2Year * Number(state().fringeBenefits);
                    break;

                case 3:
                    // week
                    //state().fringeBenefitsAnnual.w = Number(state().fringeBenefits);
                   //state().fringeBenefitsAnnual.f = week2Fortnight * Number(state().fringeBenefits);
                    //state().fringeBenefitsAnnual.m = week2Month * Number(state().fringeBenefits);
                    state().fringeBenefitsAnnual.a = week2Year * Number(state().fringeBenefits);
                    break;
                default:
                    break;
            }


            state().fringeBenefitsAnnual = spreadAnnualAmounts(state().fringeBenefitsAnnual);
            // include fringe benefits (annualised amount)
            if (state().includeFringeBenefits){
                state().baseSalary.a -= Number(state().fringeBenefitsAnnual.a)
                state().baseSalary.m -= Number(state().fringeBenefitsAnnual.m)
                state().baseSalary.f -= Number(state().fringeBenefitsAnnual.f)
                state().baseSalary.w -= Number(state().fringeBenefitsAnnual.w)
                state().baseSalary.d -= Number(state().fringeBenefitsAnnual.d)

                state().income.a -= Number(state().fringeBenefitsAnnual.a)
                state().income.m -= Number(state().fringeBenefitsAnnual.m)
                state().income.f -= Number(state().fringeBenefitsAnnual.f)
                state().income.w -= Number(state().fringeBenefitsAnnual.w)
                state().income.d -= Number(state().fringeBenefitsAnnual.d)
            }
        }


        // Skip the employers taxable benefit amount
        if (state().adjustReportableFringeBenefits === false
            && TaxData().fbt
            && TaxData().fbt.threshold
            && TaxData().fbt.type2) {
            // if (state().adjustFringeBenefits === "fringeBenefits") {
            //    state().fringeBenefitsTaxable = state().fringeBenefitsAnnual.a / Number(TaxData().fbt.type2)
            // } else {
            if (state().fringeBenefitsAnnual.a > TaxData().fbt.threshold) {
                state().reportableFringeBenefits = state().fringeBenefitsAnnual.a * Number(TaxData().fbt.type2)
            } else {
                state().reportableFringeBenefits = 0;
            }
            // }
        } else {
            //state().reportabeFringBenefits = 
            //state().fringeBenefitsTaxable = 0;
            //state().fringeBenefits = 0;

        }

        return Calculator;
    },


    ///////////////////////////////////////////
    // Superannuation (Superannuation guarantee)
    ///////////////////////////////////////////
    calculateSuperannuationGuarantee: () => {
        /// ANYTHINBG OVER STANDARD RATE IS SALARY SACRAFICED ////

        // adjusted rate
        let guaranteeRate = Number(TaxData().superannuation.rate);
        const userRate = state().adjustSuperannuationRate ? Number(state().superannuationRate) : guaranteeRate;

        let reportableRate = 0;
        let superannuationReportable = 0;
        let superannuationGuarantee = 0;

        if (userRate > guaranteeRate && state().reportableSuper) {
            reportableRate = userRate - guaranteeRate;
        } else {
            guaranteeRate = userRate;
        }

        if (state().noSuperannuation) {
            return Calculator;
        }

        // -------------------------
        // Calculate 'Super Income'
        // Not great - need to define Super income as it can vary from base Income depending on inputs (salary sacrafice)
        // Super guarentee is based on ordinary hours
        const superIncome = {
            a: state().baseSalary.a,
            m: state().baseSalary.m,
            f: state().baseSalary.f,
            w: state().baseSalary.w,
            d: state().baseSalary.d,
        }


        // super paid on overime
        superIncome.a += state().overtimeSG.a;
        superIncome.m += state().overtimeSG.m;
        superIncome.f += state().overtimeSG.f;
        superIncome.w += state().overtimeSG.w;
        superIncome.d += state().overtimeSG.d;


        // apply allowances - these are part of Ordinary hours
        if (state().allowance.a !== 0 && state().allowanceIncomeTaxable) {
            superIncome.a += state().allowance.a;
            superIncome.m += state().allowance.m;
            superIncome.f += state().allowance.f;
            superIncome.w += state().allowance.w;
        }

        // apply bonuses - these are part of Ordinary hours
        if (state().hasBonus) {
            superIncome.a += Number(state().bonusIncome);
        }

        // deduct included fringe benefits
        // if (state().fringeBenefitsAnnual.a !== 0) {
        //     superIncome.a -= Number(Number(state().fringeBenefitsAnnual.a));
        //     superIncome.m -= Number(Number(state().fringeBenefitsAnnual.m));
        //     superIncome.f -= Number(Number(state().fringeBenefitsAnnual.f));
        //     superIncome.w -= Number(Number(state().fringeBenefitsAnnual.w));
        // }



        // -------------------------

        const incSuper = state().includesSuperannuation;

        // -------------------------
        // superannuation contributions cap
        // if the income is over the Number(TaxData().superannuation.maximumContributionBaseQuarter)*4 then limit the super contribution unless superannuationGuarenteeCap is false;
        const maxBase = Number(TaxData().superannuation.maximumContributionBaseQuarter) * 4;
        const maxBaseSuper = getSuperannuation(maxBase, false, guaranteeRate);
        const maxContributionsCap = state().superannuationGuaranteeCap; // "Max contributions base cap applied"

        if (incSuper) {
            // calculate the superannuation annually
            superannuationGuarantee = getSuperannuation(superIncome.a, incSuper, guaranteeRate);

            // maxcontributions cap is active warning (using super)
            state().warnings.maximunContributionsCapActive = superannuationGuarantee >= maxBaseSuper

            if (maxContributionsCap && superannuationGuarantee >= maxBaseSuper) {
                // limit superannuationGuarantee
                superannuationGuarantee = maxBaseSuper;
                // what is the super.a?
                superIncome.a = maxBase
                state().warnings.superannuationCapped = true;
            }

            state().superannuation.a = superannuationGuarantee;
            state().superannuation.m = getSuperannuation(superIncome.m * month2Year, incSuper, guaranteeRate) / month2Year;
            state().superannuation.f = getSuperannuation(superIncome.f * fortnight2Year, incSuper, guaranteeRate) / fortnight2Year;
            state().superannuation.w = getSuperannuation(superIncome.w * week2Year, incSuper, guaranteeRate) / week2Year;
            state().superannuation.d = getSuperannuation(superIncome.d * day2Year, incSuper, guaranteeRate) / day2Year;


            // if using higher SG rate
            if (reportableRate > 0) {
                // Calculate the total superannuation first and subtract the difference

                if (maxContributionsCap && superannuationGuarantee >= maxBaseSuper) {

                    // ZERO reportable
                    superannuationReportable = 0//getSuperannuation(superIncome.a, incSuper, (reportableRate));
                    zero(state().superannuationReportable)

                } else {
                    let si = 0 // super income 
                    let si_temp = 0 //test super income
                    si = superIncome.a;

                    superannuationReportable = getSuperannuation(si, incSuper, (guaranteeRate + reportableRate)) - superannuationGuarantee;
                    state().superannuationReportable.a = superannuationReportable;

                    // Check to see if the payscale income needs to be re-computed
                    // monthly
                    si_temp = superIncome.m * month2Year;
                    if (si_temp !== si) {
                        si = si_temp
                        superannuationReportable = getSuperannuation(si, incSuper, (guaranteeRate + reportableRate)) - getSuperannuation(si, incSuper, guaranteeRate);
                    }
                    state().superannuationReportable.w = superannuationReportable / week2Year

                    // fortnightly
                    si_temp = superIncome.f * fortnight2Year;
                    if (si_temp !== si) {
                        si = si_temp
                        superannuationReportable = getSuperannuation(si, incSuper, (guaranteeRate + reportableRate)) - getSuperannuation(si, incSuper, guaranteeRate);
                    }
                    state().superannuationReportable.w = superannuationReportable / week2Year

                    // weekly
                    si_temp = superIncome.w * week2Year;
                    if (si_temp !== si) {
                        si = si_temp
                        superannuationReportable = getSuperannuation(si, incSuper, (guaranteeRate + reportableRate)) - getSuperannuation(si, incSuper, guaranteeRate);
                    }
                    state().superannuationReportable.w = superannuationReportable / week2Year

                    // daily
                    si_temp = superIncome.d * day2Year;
                    if (si_temp !== si) {
                        si = si_temp
                        superannuationReportable = getSuperannuation(si, incSuper, (guaranteeRate + reportableRate)) - getSuperannuation(si, incSuper, guaranteeRate);
                    }
                    state().superannuationReportable.w = superannuationReportable / day2Year

                }
            }


            // subtract assessable Income
            state().income.a -= state().superannuation.a;
            state().income.m -= state().superannuation.m;
            state().income.f -= state().superannuation.f;
            state().income.w -= state().superannuation.w;
            state().income.d -= state().superannuation.d;

            // subtract additional super
            state().income.a -= state().superannuationReportable.a;
            state().income.m -= state().superannuationReportable.m;
            state().income.f -= state().superannuationReportable.f;
            state().income.w -= state().superannuationReportable.w;
            state().income.d -= state().superannuationReportable.d;

            // subtract from base Income
            state().baseSalary.a -= state().superannuation.a;
            state().baseSalary.m -= state().superannuation.m;
            state().baseSalary.f -= state().superannuation.f;
            state().baseSalary.w -= state().superannuation.w;
            state().baseSalary.d -= state().superannuation.d;

            state().baseSalary.a -= state().superannuationReportable.a;
            state().baseSalary.m -= state().superannuationReportable.m;
            state().baseSalary.f -= state().superannuationReportable.f;
            state().baseSalary.w -= state().superannuationReportable.w;
            state().baseSalary.d -= state().superannuationReportable.d;

        } else {

            // maxcontributions cap is active warning
            state().warnings.maximunContributionsCapActive = superIncome.a >= maxBase

            superannuationGuarantee = getSuperannuation(superIncome.a, incSuper, guaranteeRate);
            if (maxContributionsCap && superannuationGuarantee > maxBaseSuper) {
                // limit superannuationGuarantee
                superannuationGuarantee = maxBaseSuper;
                state().warnings.superannuationCapped = true;
            }

            state().superannuation.a = superannuationGuarantee;
            state().superannuation.m = getSuperannuation(superIncome.m * month2Year, incSuper, guaranteeRate) / month2Year;
            state().superannuation.f = getSuperannuation(superIncome.f * fortnight2Year, incSuper, guaranteeRate) / fortnight2Year;
            state().superannuation.w = getSuperannuation(superIncome.w * week2Year, incSuper, guaranteeRate) / week2Year;
            state().superannuation.d = getSuperannuation(superIncome.d * day2Year, incSuper, guaranteeRate) / day2Year;


            // // calcualte the individual rates ( by bumping up to annual values)
            // if (state().warnings.superannuationCapped) {
            //     state().superannuation.m = superannuationGuarantee / month2Year;
            //     state().superannuation.f = superannuationGuarantee / fortnight2Year;
            //     state().superannuation.w = superannuationGuarantee / week2Year;
            //     state().superannuation.d = superannuationGuarantee / day2Year;
            // } else {
            //     state().superannuation.m = getSuperannuation(superIncome.m * month2Year, incSuper, guaranteeRate) / month2Year;
            //     state().superannuation.f = getSuperannuation(superIncome.f * fortnight2Year, incSuper, guaranteeRate) / fortnight2Year;
            //     state().superannuation.w = getSuperannuation(superIncome.w * week2Year, incSuper, guaranteeRate) / week2Year;
            //     state().superannuation.d = getSuperannuation(superIncome.d * day2Year, incSuper, guaranteeRate) / day2Year;

            //     //state().superannuation.d  = state().superannuation.w / daysPerWeek

            // }


            // If SG rate is above legislated amount
            if (reportableRate > 0) {
                superannuationReportable = getSuperannuation(superIncome.a, incSuper, reportableRate);
                if (maxContributionsCap && superannuationGuarantee >= maxBaseSuper) {
                    superannuationReportable = 0
                    zero(state().superannuationReportable)
                } else {

                    superannuationReportable = getSuperannuation(superIncome.a, incSuper, reportableRate);
                    state().superannuationReportable.a = superannuationReportable;

                    // monthly
                    superannuationReportable = getSuperannuation(superIncome.m * month2Year, incSuper, reportableRate);
                    state().superannuationReportable.m = superannuationReportable / month2Year;

                    // fortnightly
                    superannuationReportable = getSuperannuation(superIncome.f * fortnight2Year, incSuper, reportableRate);
                    state().superannuationReportable.f = superannuationReportable / fortnight2Year;

                    // weekly
                    superannuationReportable = getSuperannuation(superIncome.w * week2Year, incSuper, reportableRate);
                    state().superannuationReportable.w = superannuationReportable / week2Year;

                    // daily
                    superannuationReportable = getSuperannuation(superIncome.d * day2Year, incSuper, reportableRate);
                    state().superannuationReportable.d = superannuationReportable / day2Year;
                }


            }


        }

        state().superannuationGuarantee = superannuationGuarantee;


        return Calculator;
    },




    ///////////////////////////////////////////
    // Superannuationm Co-Contribution
    ///////////////////////////////////////////
    // ref: https://www.ato.gov.au/Calculators-and-tools/Host/?anchor=SuperCoContributions&anchor=SuperCoContributions#SuperCoContributions/questions

    // Additional super incentive - must be non concessional
    calculateSuperannuationCoContribution: () => {

        if (TaxData().superannuationCocontribution === undefined) {
            console.log("No 'superannuationCoContribution' data")
            return Calculator;
        }

        const min = TaxData().superannuationCocontribution.minIncome;
        const max = TaxData().superannuationCocontribution.maxIncome;
        const contribution = state().adjustSuperannuation ? state().additionalSuper : 0;


        let reportableIncome = state().baseSalary.a;
        if (state().adjustSalaryScaracfice && !state().reportableSuper) {
            reportableIncome += Number(state().superannuationSacrafice.a);
        }

        if (state().adjustOtherIncome === true) {
            reportableIncome += Number(state().otherIncome);
        }

        if (state().businessIncome) {
            reportableIncome += state().includeGST ? Number(state().businessIncome) / 1.1 : Number(state().businessIncome);
        }
        if (state().businessIncomeLoss) {
            reportableIncome -= state().businessIncomeLoss
        }

        if (state().hasBonus) {
            reportableIncome += Number(state().bonusIncome);
        }


        if (state().adjustAllowanceIncome === true && state().allowance.a > 0 && state().allowanceIncomeTaxable) {
            reportableIncome += Number(state().allowance.a);
        }

        if (state().adjustDeductions === true) {
            reportableIncome -= Number(state().taxableDeductions);
        }

        // deduct included fringe benefits
        if (Number(state().reportableFringeBenefits) > 0) {
            reportableIncome += Number(Number(state().reportableFringeBenefits));
        }

        // Eligibility
        //state().superannuationCoContributionAvailable = (reportableIncome < max && state().superannuationExcess <= 0 && !state().over71 && !state().backpacker);
        state().superannuationCoContributionAvailable = (reportableIncome < max && state().superannuationExcess <= 0 && !state().backpacker);



        // Eligibility
        if (state().superannuationCoContributionAvailable && contribution > 0 && state().superannuationCoContributionApply) {

            const reductionFactor = TaxData().superannuationCocontribution.reductionFactor;
            const maxEntitlement = TaxData().superannuationCocontribution.maxEntitlement;
            const contributionRate = TaxData().superannuationCocontribution.contributionRate;
            const minContribution = TaxData().superannuationCocontribution.minContribution;


            // Co contribution calculation
            let coContribution = maxEntitlement - (reductionFactor * (reportableIncome - min));

            // Less than maximum contribution amount
            coContribution = Math.min(maxEntitlement, coContribution);

            // lesset of 50% contribution or co contribution
            coContribution = Math.min(coContribution, contribution * contributionRate);
            // minContribution amount
            coContribution = Math.max(minContribution, coContribution);
            state().superannuationCoContribution = roundToNearestDollar(coContribution);

            // assign this as non concessional super
            state().superannuationCoContributionNonConcessional = Math.min(1000, contribution);

        }


        return Calculator;
    },




    calculateTotalSuperannuation: () => {
        // add up all the superannuation benefits and guarentees and contributions
        state().totalSuperannuation = { ...state().superannuation }

        // employer contributions
        state().totalSuperannuation.a += Number(state().superannuationSacrafice.a);
        state().totalSuperannuation.m += Number(state().superannuationSacrafice.m);
        state().totalSuperannuation.f += Number(state().superannuationSacrafice.f);
        state().totalSuperannuation.w += Number(state().superannuationSacrafice.w);
        state().totalSuperannuation.d += Number(state().superannuationSacrafice.d); // should always be zero

        state().totalSuperannuation.a += Number(state().superannuationReportable.a);
        state().totalSuperannuation.m += Number(state().superannuationReportable.m);
        state().totalSuperannuation.f += Number(state().superannuationReportable.f);
        state().totalSuperannuation.w += Number(state().superannuationReportable.w);
        state().totalSuperannuation.d += Number(state().superannuationReportable.d);


        // Prepare the values used to split concessional and non concessional
        let nonConcessionalCap = 0;
        let concessionalCap = 0;
        let concessionalTax = 0;
        let nonConcessionalExcessTax = 0;
        if (TaxData().superannuation &&
            TaxData().superannuation.nonConcesssionalCap !== undefined &&
            TaxData().superannuation.concessionalCap !== undefined &&
            TaxData().superannuation.concessionalTax !== undefined &&
            TaxData().superannuation.nonConcessionalExcessTax !== undefined
        ) {
            nonConcessionalCap = TaxData().superannuation.nonConcesssionalCap;
            concessionalCap = TaxData().superannuation.concessionalCap;
            concessionalTax = TaxData().superannuation.concessionalTax;
            nonConcessionalExcessTax = TaxData().superannuation.nonConcessionalExcessTax;
        } else {
            console.log("No 'superannuation.cap...' data", TaxData().superannuation.cap)
        }


        // calculate concessional cap with carry forward amounts
        if (TaxData().superannuationCarryForward !== undefined && state().adjustSuperannuationCarryForward) {
            concessionalCap += Number(state().superannuationCarryForward);
        }

        // reset all categories
        state().superannuationConcessional = 0;
        state().superannuationConcessionalTax = 0;
        state().superannationNonConcessional = 0;
        state().superannuationExcess = 0;
        state().superannuationExcessTax = 0;
        state().superannationConcessionalRemaining = concessionalCap;
        state().superannationNonConcessionalRemaining = nonConcessionalCap;
        state().superannationTax = 0;


        // Split the super into concessinal and non concesisonal buckets
        // Make sure co-contribution amount is not concessional

        // Super base concessionalCap Warning
        if (state().superannuation.a >= concessionalCap) {
            // Super guarntee has been capped
            state().warnings.superannuation.push("Your base superannuation guarantee exceeds the limit of $" + formatMoney(concessionalCap, 0));
        }

        if (state().totalSuperannuation.a <= concessionalCap) {
            // all super is concessional
            state().superannuationConcessional = state().totalSuperannuation.a;

        } else if (state().totalSuperannuation.a <= (nonConcessionalCap + concessionalCap)) {
            // max out concessional and add remainder to non concessional
            state().superannuationConcessional = concessionalCap
            state().superannationNonConcessional = state().totalSuperannuation.a - concessionalCap;
            state().warnings.superannuation.push("You have non-concessional superannuation of $" + formatMoney(state().superannationNonConcessional, 0));
        } else {

            // excess super
            state().superannuationConcessional = concessionalCap
            state().superannationNonConcessional = nonConcessionalCap;
            state().superannuationExcess = state().totalSuperannuation.a - concessionalCap - nonConcessionalCap;
            state().warnings.superannuation.push("You have an excess of non-concessional superannuation of $" + formatMoney(state().superannuationExcess, 0));
        }

        // remaining concessional
        state().superannationConcessionalRemaining = concessionalCap - state().superannuationConcessional;


        // Calculate concession on personal contributions
        if (state().adjustSuperannuation) {

            // maximise additional voluntary super
            if (state().additionalSuperMaximise) {
                state().additionalSuper = Math.max(0, state().superannationConcessionalRemaining + state().superannuationCoContributionNonConcessional);
            }

            state().concessionalAdditionalSuper = Math.min(state().superannationConcessionalRemaining, (state().additionalSuper - state().superannuationCoContributionNonConcessional));

            // reduce taxable income
            state().income.a -= state().concessionalAdditionalSuper;

            // employee contributions
            state().totalSuperannuation.a += Number(state().additionalSuper);

            // remove the non concessional component of the co contribution ( if appicable)
            let conditionalSuper = state().totalSuperannuation.a - state().superannuationCoContributionNonConcessional;

            // adjust non concessional 
            if (conditionalSuper <= concessionalCap) {
                state().superannuationConcessional = conditionalSuper;// - state().superannuationCoContributionNonConcessional;
            } else if (conditionalSuper <= (nonConcessionalCap + concessionalCap)) {
                state().superannuationConcessional = concessionalCap
                state().superannationNonConcessional = conditionalSuper - concessionalCap;
            } else {
                // excess super
                state().superannuationConcessional = concessionalCap
                state().superannationNonConcessional = nonConcessionalCap;
                state().superannuationExcess = state().totalSuperannuation.a - concessionalCap - nonConcessionalCap;
                state().warnings.superannuation.push("You have an excess of non-concessional superannuation of $" + formatMoney(state().superannuationExcess, 0));
            }

        }


        // remaining limits
        state().superannationConcessionalRemaining = concessionalCap - state().superannuationConcessional;
        state().superannationNonConcessionalRemaining = nonConcessionalCap - state().superannationNonConcessional;

        // Superannuation concessions tax (tax paid by super fund)
        //const taxableSuper = Math.min(concessionalCap, state().totalSuperannuation.a);

        //state().superannuationConcessionalTax = state().totalSuperannuation.a * concessionalTax
        // only concessional contrubtions are liable for the supercontributions rtax
        state().superannuationConcessionalTax = state().superannuationConcessional * concessionalTax

        // Excess superannuation taxed at fixed rate (47%);
        state().superannuationExcessTax = state().superannuationExcess * nonConcessionalExcessTax;

        state().superannuationTax.a = state().superannuationConcessionalTax;
        state().superannuationTax.a = Math.max(0, state().superannuationTax.a);

        //spreadAnnualAmounts(state().superannuationTax)

        state().superannuationTax.m = state().totalSuperannuation.m * concessionalTax;
        state().superannuationTax.f = state().totalSuperannuation.f * concessionalTax;
        state().superannuationTax.w = state().totalSuperannuation.w * concessionalTax;
        state().superannuationTax.d = state().totalSuperannuation.d * concessionalTax;



        // Government co-contribution - added after tax
        state().totalSuperannuation.a += state().superannuationCoContribution


        // non concessional super that is taxable
        // start by assuming that all non-con is taxable
        let taxedNonConcessional = state().superannationNonConcessional;

        let nonConcessionalAdditionalSuper = state().additionalSuper - state().concessionalAdditionalSuper;


        // THIS SHOULD NOT INCLUDE VOLUNTARY SUPER
        if (state().adjustSuperannuation) taxedNonConcessional = Math.max(0, state().superannationNonConcessional - nonConcessionalAdditionalSuper);
        state().superannuationUntaxedNonConcessional = taxedNonConcessional;
        state().superannuationExcessConcessionalContributionsOffset = taxedNonConcessional * 0.15

        // increase taxable income
        state().income.a += taxedNonConcessional;


        // co contribution
        state().superannationNonConcessional += state().superannuationCoContributionNonConcessional;

        if (Calculator.isDebug) console.log("calculateTotalSuperannuation -> superannationConcessionalRemaining:", state().superannationConcessionalRemaining, concessionalCap, state().superannuationConcessional, "superannationNonConcessional: ", state().superannationNonConcessional)

        return Calculator;
    },


    calculateSuperannuationLISTO: () => {

        if (TaxData().superannuationLISTO === undefined) {
            state().listo = 0;
            console.log("No 'listo' data")
            return Calculator;
        }

        const limit = TaxData().superannuationLISTO.maxIncome;


        // this is done later in " CalculateTaxable income", but it is duplicated here for this calculation
        let adjustedTaxableIncome = state().income.a;


        // reportable super contributions are the contributions made by you or your employee on top of the super guarentee
        if (state().adjustSalaryScaracfice) {
            adjustedTaxableIncome += Number(state().superannuationSacrafice.a);
        }
        if (state().superannuationReportable.a > 0) {
            adjustedTaxableIncome += Number(state().superannuationReportable.a)
        }
        // voluntry concessional
        if (state().concessionalAdditionalSuper > 0) {
            adjustedTaxableIncome += Number(state().concessionalAdditionalSuper);
        }


        if (state().adjustDeductions === true) {
            adjustedTaxableIncome -= Number(state().taxableDeductions);
        }

        if (state().adjustFringeBenefits !== false) {
            adjustedTaxableIncome += Number(state().reportableFringeBenefits);
        }


        if (state().adjustOtherIncome) {
            adjustedTaxableIncome += Number(state().otherIncome);
        }
        if (state().businessIncome) {
            adjustedTaxableIncome += state().includeGST ? Number(state().businessIncome) / 1.1 : Number(state().businessIncome);
        }
        if (state().businessIncomeLoss !== 0) {
            adjustedTaxableIncome -= state().businessIncomeLoss
        }
        if (state().hasBonus) {
            adjustedTaxableIncome += Number(state().bonusIncome);
        }
        if (state().frankingCredits > 0) {
            adjustedTaxableIncome += Number(state().frankingCredits);
        }

        // dependants
        if (state().dependants === true && state().dependantsCount > 0) {
            adjustedTaxableIncome -= Number(state().childSupport);
        }

        // Eligibility
        if (adjustedTaxableIncome <= limit) {
            const rate = TaxData().superannuationLISTO.contributionRate;
            const maxEntitlement = TaxData().superannuationLISTO.maxEntitlement;
            const minContribution = TaxData().superannuationLISTO.minContribution;

            let LISTO = state().superannuation.a * rate;

            LISTO = Math.min(maxEntitlement, LISTO);
            LISTO = Math.max(minContribution, LISTO);

            // LISTO = Math.min( state().superannuationTax.a, LISTO);
            // offset can't be greater than the tax applied
            if ((state().superannuationTax.a - LISTO) < 0) { LISTO = state().superannuationTax.a } // offset cannot be less than incomeTax

            state().listo = LISTO;

        } else {
            // reset
            state().listo = 0;
        }

        return Calculator;
    },


    calculateTotalSuperannuationTax: () => {

        // Deduct LISTO from super tax
        state().superannuationTax.a = state().superannuationTax.a - state().listo;
        state().superannuationTax.a = Math.max(0, state().superannuationTax.a);

        return Calculator;
    },

    calculateSpouseSuperannuationOffset: () => {
        if (state().adjustSpouseSuper) {
            state().spouseSuperTaxOffset = getSpouseSuperTaxOffset(state().spouseIncome, state().spouseSuperAmount);
        }
        return Calculator;
    },




    calculateTaxableIncome: () => {

        // taxable income
        // state().income is used for taxable Income that included approprite deductions
        // subtract deductions from income and save as taxableIncome

        // !!! Some Super tax deductions have been made in the calculate Superannuation block !!!

        // if (state().superannationTaxableNonConcessional.a > 0) {
        //     // this should not include voluntary super!!
        //     console.log("state().superannationTaxableNonConcessional.a): ", state().superannationTaxableNonConcessional.a)
        //     state().income.a += Number(state().superannationTaxableNonConcessional.a);
        // }

        // deductions is the sum of all variationa over the base salary

        if (state().adjustDeductions === true) {
            // subtract
            state().deductions.a += Number(state().taxableDeductions);
        }

        if (state().capitalGains > 0) {
            // add to annual income 
            state().deductions.a -= Number(state().capitalGains);
        }

        if (state().adjustOtherIncome) {
            // add to annual income 
            state().deductions.a -= Number(state().otherIncome);
        }

        if (state().hasBonus) {
            if (Number(state().bonusIncome) < 0) { state().bonusIncome = 0 }
            if (isNaN(state().bonusIncome)) { state().bonusIncome = 0; }

            // add to annual income 
            state().deductions.a -= Number(state().bonusIncome);
        }



        if (state().businessIncome) {
            // add to annual income
            state().deductions.a -= state().includeGST ? Number(state().businessIncome) / 1.1 : Number(state().businessIncome);
        }

        if (state().businessIncomeLoss) {
            // add to annual income
            state().deductions.a += state().businessIncomeLoss
        }

        if (state().frankingCredits > 0) {
            // add to annual income
            state().deductions.a -= state().frankingCredits
        }


        // if (state().adjustSalaryScaracfice) {
        //     // add to annual income
        //     state().deductions.a += state().superannuationSacrafice.a
        //     state().deductions.w += state().superannuationSacrafice.w
        // }



        // subtract deductions from income
        state().income.a -= Number(state().deductions.a);
        state().income.m -= Number(state().deductions.m);
        state().income.f -= Number(state().deductions.f);
        state().income.w -= Number(state().deductions.w);
        state().income.d -= Number(state().deductions.d);

        // Can't have negative income
        state().income.a = Math.max(0, state().income.a);
        state().income.m = Math.max(0, state().income.m);
        state().income.f = Math.max(0, state().income.f);
        state().income.w = Math.max(0, state().income.w);
        state().income.d = Math.max(0, state().income.d);

        // ---------------- Adjusted taxable income ------------
        // Adjusted Taxable Income is taxable income + all other income
        //https://www.ato.gov.au/Individuals/Tax-return/2019/Tax-return/Adjusted-taxable-income-(ATI)-for-you-and-your-dependants-2019/
        /*
                state().adjustedAnnualTaxableIncome = state().baseSalary.a;
                state().adjustedWeeklyTaxableIncome = state().baseSalary.w;
        
        
                if (state().overtimeSalary.a) {
                    state().adjustedAnnualTaxableIncome += Number(state().overtimeSalary.a);
                    state().adjustedWeeklyTaxableIncome += Number(state().overtimeSalary.w);
                }
        
        
                // allowances
                if (state().adjustAllowanceIncome === true) {
                    state().adjustedAnnualTaxableIncome += state().allowance.a;
                    state().adjustedWeeklyTaxableIncome += state().allowance.w;
                }
        */
        let adjustedAnnualTaxableIncome = 0;
        let adjustedWeeklyTaxableIncome = 0;


        adjustedAnnualTaxableIncome = state().baseSalary.a + Number(state().overtimeSalary.a) - Number(state().deductions.a);
        adjustedWeeklyTaxableIncome = state().baseSalary.w + Number(state().overtimeSalary.w) - Number(state().deductions.w);

        adjustedAnnualTaxableIncome = Math.max(0, adjustedAnnualTaxableIncome);

        ////////////////////// Annual amounts ///////////////////////

        // if (state().adjustDeductions === true) {
        //     adjustedAnnualTaxableIncome -= Number(state().taxableDeductions);
        // }

        if (state().adjustFringeBenefits !== false) {
            // adjustedAnnualTaxableIncome -= Number(state().fringeBenefitsAnnual.a);
            adjustedAnnualTaxableIncome += Number(state().reportableFringeBenefits);
        }

        /*
        For income test purposes, the amounts you paid and the benefits you provided for the maintenance of your child will be deducted from the total of the other components that make up your adjusted taxable income.
        https://www.ato.gov.au/Individuals/myTax/2020/In-detail/Income-tests/?page=8
        */

        if (state().dependants === true && state().dependantsCount > 0) {
            adjustedAnnualTaxableIncome -= Number(state().childSupport);
        }

        if (state().capitalGains < 0) {
            adjustedAnnualTaxableIncome += Number(state().capitalGains);
        }



        // Reportable Super Contributions

        // reportable super contributions are the contributions made by you or your employee on top of the super guarentee
        if (state().adjustSalaryScaracfice) {
            // this has already been removed from income
            //adjustedAnnualTaxableIncome += Number(state().superannuationSacrafice.a);
        }
        if (state().superannuationReportable.a > 0) {
            adjustedAnnualTaxableIncome += Number(state().superannuationReportable.a)
        }
        // voluntry concessional
        if (state().concessionalAdditionalSuper > 0) {
            // already removed from income
            //adjustedAnnualTaxableIncome += Number(state().concessionalAdditionalSuper);
        }




        // // Other income or loss
        // if (state().adjustOtherIncome) {
        //     adjustedAnnualTaxableIncome += Number(state().otherIncome);
        // }

        // // Business income
        // if (state().businessIncome) {
        //     adjustedAnnualTaxableIncome += state().businessIncome;
        // }

        // allowances
        if (state().adjustAllowanceIncome === true && state().allowance.a > 0 && state().allowanceIncomeTaxable) {
            adjustedAnnualTaxableIncome += state().allowance.a;
            adjustedWeeklyTaxableIncome += state().allowance.w;
        }

        adjustedAnnualTaxableIncome = Math.max(0, adjustedAnnualTaxableIncome);


        // -------------- Division 293 income ------------------------
        let division293Income = Number(state().income.a) // taxable income
        /// fringe benefits
        if (state().reportableFringeBenefits > 0) {
            division293Income += Number(state().reportableFringeBenefits)
        }
        if (state().businessIncomeLoss > 0) {
            division293Income += Number(state().businessIncomeLoss)
        }

        state().division293Income = division293Income


        // reportable Super contributions
        adjustedAnnualTaxableIncome += state().superannuationReportable.a
        adjustedWeeklyTaxableIncome += state().superannuationReportable.w
        state().adjustedAnnualTaxableIncome = adjustedAnnualTaxableIncome;
        state().adjustedWeeklyTaxableIncome = adjustedWeeklyTaxableIncome;


        // ---------------- Rebate income ------------
        state().rebateIncome = adjustedAnnualTaxableIncome;
        if (state().adjustFringeBenefits !== false) {
            // Rebate income (SAPTO) is 53% of fringe benefits;
            // https://www.ato.gov.au/forms-and-instructions/withholding-declaration-calculating-your-tax-offset/calculate-a-seniors-and-pensioners-tax-offset
            state().rebateIncome += state().reportableFringeBenefits * 0.53
        }


        // ----------------- Medicare Surcharge income --------------
        let medicareSurchargeIncome = adjustedAnnualTaxableIncome;
        // this sholdn'd be taken off, so add it back on - same for Div 293
        if (state().dependants === true && state().dependantsCount > 0) {
            medicareSurchargeIncome += Number(state().childSupport);
        }

        // Does not include any additional concessional super - REMOVED!!
        // medicareSurchargeIncome -= state().additionalSuper ? state().concessionalAdditionalSuper : 0

        state().medicareSurchargeIncome = medicareSurchargeIncome;

        if (Calculator.isDebug) console.log("Calculate Taxable income -> income: ", state().income, "  adjustedTaxableIncome:", state().adjustedTaxableIncome);

        return Calculator;
    },


    ///////////////////////////////////////////
    // Divisioin 293
    ///////////////////////////////////////////
    // Calcualtion of division293 based on annual gross income
    // If your income and concessional contributions (CCs) are more than $250,000 in 2020/21, you may have to pay an additional 15% tax on some or all of your CCs
    calculateDivision293: () => {

        // Division 293 was introduced in 2012
        if (!TaxData().division293) {
            state().division293 = 0;
            return Calculator;
        }

        // if your income plus your super is over the 293 threshold
        // Calculate 293 on 15% of the taxable income or concessional superannuation (will invariably be the latter)

        /*
        Division 293 income
         https://www.ato.gov.au/Individuals/Super/In-detail/Growing-your-super/Division-293-tax---information-for-individuals/?page=6
        */

        const division293Income = state().division293Income;

        let division293Contributions = state().superannuationConcessional;


        const threshold = TaxData().division293.threshold;
        const rate = TaxData().division293.rate;



        let division293Tax = 0;

        if ((division293Income + division293Contributions) > threshold) {
            // You're liable to pay Division 293 tax if you exceed the income threshold and you have taxable contributions for an income year.
            // If your Division 293 income plus your Division 293 super contributions are greater than the Division 293 threshold, the taxable contributions will be the lesser of the Division 293 super contributions and the amount above the threshold.

            let divisionLiability = (division293Income + division293Contributions) - threshold;
            divisionLiability = Math.min(division293Contributions, divisionLiability);

            if (Calculator.isDebug) console.log("DIV 293: ", state().adjustedAnnualTaxableIncome, state().superannuationConcessional, division293Income, division293Contributions, threshold, divisionLiability)

            division293Tax = (divisionLiability * (rate / 100));
        }

        state().division293 = division293Tax;
        // this should be added to annual tax - do this in the income tax calculation
        return Calculator;
    },


    ///////////////////////////////////////////
    // Income tax
    ///////////////////////////////////////////
    calculateIncomeTax: () => {

        // determine the correct tax function
        let taxFunction = {};
        let taxFunctionPAYG = {};
        if (state().backpacker) {
            if (state().haveTFN) {
                taxFunction = getBackpackerTax;
                taxFunctionPAYG = getPAYGBackpackerTax;
            } else {
                taxFunction = getNonResidentTax;
                taxFunctionPAYG = getPAYGNonResidentTax;
            }
        } else if (state().nonResident) {
            taxFunction = getNonResidentTax;
            taxFunctionPAYG = getPAYGNonResidentTax;
        } else if (state().noTaxFree) {
            taxFunction = getNoTaxFreeThresholdTax;
            taxFunctionPAYG = getPAYGNoTaxFreeThresholdTax;
        } else {
            // TYPICAL
            taxFunction = getIncomeTax;

            if (state().medicareExemption) {
                if (Number(state().medicareExemptionValue) === 1) {
                    taxFunctionPAYG = getPAYGIncomeTaxFullMedicare;

                } else {
                    taxFunctionPAYG = getPAYGIncomeTaxHalfMedicare;
                }
            } else {
                // TYPICAL
                taxFunctionPAYG = getPAYGIncomeTax;
            }
        }


        //-----------------------------------------------------------------------
        // Pre budget calculation for 2021
        //-----------------------------------------------------------------------

        if (state().year === "2021") {
            // calculate the previous PAYG rates
            useLegacyTaxData = true;
            state().incomeTaxWithheldMonthlyLegacy = taxFunctionPAYG(state().income.m, MONTHLY);
            useLegacyTaxData = false;
        }

        // calculate tax
        state().incomeTax.a = (taxFunction());
        /*
                // tax liabilities / savings
                if (state().adjustDeductions) {
                    state().taxableDeductionsTax = taxFunction(state().income.a + state().taxableDeductions) - state().incomeTax.a;
                }
        
                if (state().capitalGains !== 0) {
                    if (state().capitalGains > 0) {
                        state().capitalGainsTax = taxFunction(state().income.a - state().capitalGains) - state().incomeTax.a;
                    } else {
                        if (state().HELP) {
                            if (state().nonResident || state().noTaxFree) {
                                taxFunction = getHELP_noTaxFree
                            } else {
                                taxFunction = getHELP;
                            }
                            // reportable income appears to be the same as ATI
                            const reportableIncome = state().adjustedAnnualTaxableIncome
                            const after = roundToNearestCent(taxFunction(reportableIncome));
                            const before = roundToNearestCent(taxFunction(reportableIncome - state().capitalGains));
        
                            state().capitalGainsTax = before - after;
                        }
                    }
                }
        
                if (state().adjustOtherIncome) {
                    state().otherIncomeTax = taxFunction(state().income.a - state().otherIncome) - state().incomeTax.a;
                }
        
                if (state().hasBonus) {
                    state().bonusIncomeTax = taxFunction(state().income.a - Number(state().bonusIncome)) - state().incomeTax.a;
                }
        
        
                if (state().businessIncome) {
                    let businessIncome = state().includeGST ? state().businessIncome / 1.1 : state().businessIncome
                    state().businessIncomeTax = taxFunction(state().income.a - businessIncome) - state().incomeTax.a;
                }
        
                if (state().businessIncomeLoss) {
                    state().businessIncomeLossTax = taxFunction(state().income.a + state().businessIncomeLoss) - state().incomeTax.a;
                }
        
        
                // salary sacrafice
        
                if (state().salarySacraficeIncome.a > 0) {
                    state().salarySacraficeIncomeSavings = taxFunction(state().income.a + state().salarySacraficeIncome.a) - state().incomeTax.a;
                }
        
        */

        state().incomeTax.m = taxFunctionPAYG(state().income.m, MONTHLY)
        state().incomeTax.f = taxFunctionPAYG(state().income.f, FORTNIGHTLY)
        state().incomeTax.w = taxFunctionPAYG(state().income.w, WEEKLY);
        state().incomeTax.d = taxFunctionPAYG(state().income.d, DAILY);

        let bracket = getBracket(state().income.a, TaxData().tax.brackets)
        state().marginalTaxRate = bracket ? Number(bracket.value) : 0;


        // Excess super contributions tax offset
        //superannuationExcessConcessionalContributionsOffset

        return Calculator;
    },


    ///////////////////////////////////////////
    // Income tax - Extra witholding
    ///////////////////////////////////////////
    // Only for weeks or fortnight with 53 or 26 payments
    calculateIncomeTaxExtraWitholding: () => {

        // if (TaxData().extraWitholding) {

        if (state().payments.w === 53) {
            let bracket = TaxData().extraWitholding.weekly.brackets;
            state().extraWithholdingTax.w = calculateBracket(state().income.w, bracket, false);
        }

        if (state().payments.f === 27) {
            let bracket = TaxData().extraWitholding.fortnightly.brackets;
            state().extraWithholdingTax.f = calculateBracket(state().income.f, bracket, false);

        }

        return Calculator;
    },



    ///////////////////////////////////////////
    // Student loans
    ///////////////////////////////////////////
    //  From 1 July 2019 VET Student Loan (VSL) and Student Financial Supplement Scheme (SFSS) debts will be repaid after Higher Education Loan Program (HELP) debts are discharged

    calculateStudentLoans: () => {
        if (!state().HELP) return Calculator;

        // HELP (HECS)
        let taxFunction = {};

        // if( state().medicareExemption && state().medicareExemptionValue === 1){
        //     // don't repay student loan
        //     return Calculator;
        // }

        if (state().HELP) {
            if (!state().nonResident && state().noTaxFree) {
                taxFunction = getHELP_noTaxFree
            } else {
                taxFunction = getHELP;
            }
            // reportable income appears to be the same as ATI
            const reportableIncome = state().adjustedAnnualTaxableIncome
            const reportableIncome_d = state().adjustedWeeklyTaxableIncome / 5
            const reportableIncome_w = state().adjustedWeeklyTaxableIncome
            const reportableIncome_f = state().adjustedWeeklyTaxableIncome * week2Fortnight
            const reportableIncome_m = state().adjustedWeeklyTaxableIncome * week2Month

            // annual
            state().help.a = ignoreCents(taxFunction(reportableIncome));

            if (TaxData().help_noTaxFree.payg && (state().noTaxFree)) {
                state().help.w = calculatePAYG(reportableIncome_w, TaxData().help_noTaxFree.payg, WEEKLY);
                state().help.f = calculatePAYG(reportableIncome_f, TaxData().help_noTaxFree.payg, FORTNIGHTLY);
                state().help.m = calculatePAYG(reportableIncome_m, TaxData().help_noTaxFree.payg, MONTHLY);
                state().help.d = calculatePAYG(reportableIncome_d, TaxData().help_noTaxFree.payg, DAILY);
            } else if (TaxData().help.payg && !state().noTaxFree) {
                state().help.w = calculatePAYG(reportableIncome_w, TaxData().help.payg, WEEKLY);
                state().help.f = calculatePAYG(reportableIncome_f, TaxData().help.payg, FORTNIGHTLY);
                state().help.m = calculatePAYG(reportableIncome_m, TaxData().help.payg, MONTHLY);
                state().help.d = calculatePAYG(reportableIncome_d, TaxData().help.payg, DAILY);

            } else {
                // annual calculation includes additional income   
                state().help.d = roundToNearestDollar((taxFunction(state().adjustedWeeklyTaxableIncome * week2Year)) / day2Year);
                state().help.w = roundToNearestDollar((taxFunction(state().adjustedWeeklyTaxableIncome * week2Year)) / week2Year);
                state().help.f = roundToNearestDollar((taxFunction(state().adjustedWeeklyTaxableIncome * week2Year)) / fortnight2Year);
                state().help.m = roundToNearestDollar((taxFunction(state().adjustedWeeklyTaxableIncome * week2Year)) / month2Year);

            }
        }
        return Calculator;
    },



    ///////////////////////////////////////////
    // Medicare
    ///////////////////////////////////////////
    calculateMedicare: () => {

        // Medicare
        if (state().nonResident || state().backpacker) {
            state().medicareSurcharge = 0;
            return Calculator;
        }

        const family = state().spouse || state().dependants;
        const senior = state().SAPTO;
        const dependantsCount = state().dependants ? Number(state().dependantsCount) : 0;

        // Medicare assesment income
        let medicareSurchargeIncome = Number(state().medicareSurchargeIncome)
        state().familyIncome = Number(state().adjustedAnnualTaxableIncome) + Number(state().spouseIncome);


        if (family) {
            state().medicare.a = getMedicareFamily(Number(state().income.a), Number(state().spouseIncome), dependantsCount, senior);
            // don't go below 0
            state().medicare.a = Math.max(0, state().medicare.a);

        } else {
            state().medicare.a = getMedicare(Number(state().income.a), senior);

            // don't go below 0
            state().medicare.a = Math.max(0, state().medicare.a);
        }
        state().medicare.a = (state().medicare.a)

        // basline PAYG medicare levy
        // standard unadjusted medicare levy applied to PAYG (income excludes other income)
        const medicareBaseline = getMedicare(state().income.w * week2Year);

        state().medicare.m = medicareBaseline / month2Year;
        state().medicare.f = medicareBaseline / fortnight2Year;
        state().medicare.w = medicareBaseline / week2Year;
        state().medicare.d = medicareBaseline / day2Year;

        // ---------------  Medicare Surcharge
        if (family) {
            state().medicareSurcharge = getMedicareSurchargeFamily(state().familyIncome, medicareSurchargeIncome, dependantsCount);
        } else {
            state().medicareSurcharge = getMedicareSurchargeSingle(state().adjustedAnnualTaxableIncome, medicareSurchargeIncome);
        }

        if (state().hasPrivateHealthcare || state().medicareExemption) {
            state().medicareSurchargeLiability = 0;
        } else {
            state().medicareSurchargeLiability = state().medicareSurcharge;
        }

        let medicareDescription = "";
        if (senior) {
            medicareDescription = "Senior, "
        }

        if (family) {
            medicareDescription += "Family, "
        } else {
            medicareDescription += "Single, "
        }

        if (dependantsCount === 0) {
            medicareDescription += "no dependants. "
        } else if (dependantsCount === 1) {
            medicareDescription += "1 dependant. "
        } else {
            medicareDescription += dependantsCount + " dependants. ";
        }

        // if (!state().hasPrivateHealthcare && state().medicareSurcharge > 0) {
        //     medicareDescription += `Includes surcharge of $${formatMoney(state().medicareSurcharge, 0)}`;
        // }

        state().medicareDescription = medicareDescription;

        return Calculator;

    },

    calculateMedicareAdjustment: () => {

        // Medicare shade-in for scale 2 (Claim tax free threshold) and scale 6 (Half Medicare) only
        // if (state().backpacker || state().nonResident || state().noTaxFree || state().medicareExemptionValue === 1) {
        if (state().backpacker || state().nonResident || state().noTaxFree) {
            return Calculator;
        }

        let scale = state().medicareExemptionValue === 0.5 ? SCALE6 : SCALE2;
        const dependantsCount = state().dependants ? Number(state().dependantsCount) : 0;
        const family = dependantsCount > 0;

        let WLA = getMedicareAdjustment(state().income.a, dependantsCount, state().spouse, state().spouseIncome / 52, scale);

        if (state().medicareExemption !== false) {
            state().medicare.w -= state().medicare.w * state().medicareExemptionValue;
            state().medicare.d -= state().medicare.d * state().medicareExemptionValue;
            state().medicare.f -= state().medicare.f * state().medicareExemptionValue;
            state().medicare.m -= state().medicare.m * state().medicareExemptionValue;
            state().medicare.a -= state().medicare.a * state().medicareExemptionValue;
        }

        if (WLA > 0 && family) {
            state().medicareFamilyAdjustment = true;

        } else {
            // disable this option
            state().applyMedicareAdjustment = false;
        }

        state().medicareAdjustment.w = WLA;

        state().medicareAdjustment.f = WLA * 2;
        state().medicareAdjustment.m = (WLA * 13) / 3;

        // the adjustment is only used to reduce PAYG
        state().medicareAdjustment.a = 0;

        // remove silly -0 and +0 
        cleanZeros(state().medicareAdjustment);

        return Calculator;

    },



    ///////////////////////////////////////////
    // Offsets
    ///////////////////////////////////////////
    calculateOffsets: () => {

        if (state().nonResident || state().noTaxFree || state().backpacker) return Calculator;

        //    The eligibility for the low income tax offset is based on taxable income (not ATI)

        state().lito = getLITO(state().income.a);
        if ((state().incomeTax.a - state().lito) < 0) {
            state().lito = state().incomeTax.a 
        }
        state().lamito = getLAMITO(state().income.a)
        if ((state().incomeTax.a - state().lamito) < 0) {
            state().lamito = state().incomeTax.a 
        }

        // state().lito = getLITO(state().adjustedAnnualTaxableIncome);
        // state().lamito = getLAMITO(state().adjustedAnnualTaxableIncome);

        if (state().SAPTO) {
            // spouseIncome is weekly income
            state().sapto = getSAPTO(state().rebateIncome, state().spouse, state().spouseSeparated, state().spouseIncome);
        }
        if ((state().incomeTax.a - state().sapto) < 0) {
            state().sapto = state().incomeTax.a 
        }

        state().offsets.a = state().lito + state().mawto + state().mito + state().lamito + state().sapto + state().spouseSuperTaxOffset;

        // prevent any annual tax offests from exceeding income tax
        // This is income tax and not gross tax
        // console.log("tax overflow: ", state().incomeTax.a + state().offsets.a)
        if ((state().incomeTax.a - state().offsets.a) < 0) {
            state().offsets.a = state().incomeTax.a;
        }

        return Calculator;
    },



    ///////////////////////////////////////////
    // FTB Family Tax Benefit
    ///////////////////////////////////////////
    // ftb_A:0,
    // ftb_A_Supplement:0,
    // ftb_B:0,
    // ftb_B_Supplement:0,
    calculateFTB: () => {
        if (state().children && state().children.length > 0) {

            let ftb_a = 0
            let ftb_a_supplement = 0
            let ftb_b = 0
            let ftb_b_supplement = 0
            let eligibleChildren = 0
            let numChild = 0; // children under teenage year (13)
            let numTeen = 0 // children 13 or over
            let youngestChild = 20;
            state().children.forEach(child => youngestChild = child.age <= youngestChild ? child.age : youngestChild)
            state().children.forEach(child => {
                if (child.age < FTB_A.teenage) numChild++;
                else if (child.age <= FTB_A.maxAge) numTeen++;
            })
            eligibleChildren = numChild + numTeen

            let careRatio = calculateBracket(state().ftb_carePercent, FTB_CATEGORIES)

            //------------------------------
            // Maintenance Inocme Test
            //------------------------------
            //Generally, the more child support you get, or you’re entitled to get, the less FTB Part A we pay you.
            let MIFA = 0 // Maintenance Income Free Area
            let maintenanceIncome_liability = 0; // amount over the Maintenance Free Area


            if (state().spouse) {
                MIFA = FTB_A.MIFA_couple + Math.max(0, (eligibleChildren - 1) * FTB_A.MIFA_additional_children)
                maintenanceIncome_liability = Math.max(0, (state().maintenance + state().maintenance_partner) - MIFA)
            } else {
                MIFA = FTB_A.MIFA_single + Math.max(0, (eligibleChildren - 1) * FTB_A.MIFA_additional_children)
                maintenanceIncome_liability = Math.max(0, (state().maintenance + state().maintenance_partner) - MIFA)
            }

            // check this ...
            // is is not  seperate reduction by 50 cents per dollar over?
            let familyAdjustedIncome = state().adjustedAnnualTaxableIncome// + maintenanceIncome_liability
            if (state().spouse) familyAdjustedIncome += state().spouseIncome


            //------------------------------
            // FTB A
            //------------------------------

            let baseRate = eligibleChildren * FTB_A.baseRate
            let maxRate = 0;
            state().children.forEach(child => {
                let bracket = getBracketIncusive(child.age, FTB_A.maxRate, 0, true);
                maxRate += bracket.value
            })

            ftb_a = maxRate;
            let ftb_a_annual = 0 // annualise FTB_a ammount

            let incomeLimit = FTB_A.HIFA; // base rate must apply at this limit
            let upperLimit = FTB_A.HIFA;
            let incomeThresholds = FTB_A.dependents[eligibleChildren] || FTB_A.dependents[FTB_A.dependents.length - 1]

            if (incomeThresholds.incomeLimit && incomeThresholds.incomeLimit.length > numChild) {
                incomeLimit = incomeThresholds.incomeLimit[numChild]

            } else {
                incomeLimit = incomeThresholds.incomeLimit[incomeThresholds.incomeLimit.length - 1]
            }

            if (incomeThresholds.upperLimit.length < numChild) upperLimit = incomeThresholds.upperLimit[numChild]
            else upperLimit = incomeThresholds.upperLimit[incomeThresholds.upperLimit.length - 1]


            // Method 1 - reduce by 20 cents over income free area, or until base is reached
            const FORTNIGHT_ANNUAL = 26


            if (familyAdjustedIncome >= FTB_A.IFA) {
                ftb_a_annual = Math.max(baseRate * FORTNIGHT_ANNUAL, maxRate * FORTNIGHT_ANNUAL - (familyAdjustedIncome - FTB_A.IFA) * FTB_A.IFA_RATE)
                ftb_a = ftb_a_annual / FORTNIGHT_ANNUAL
            }
            // Only pay you the base rate of FTB Part A once you reach the income limit.
            if (familyAdjustedIncome >= incomeLimit) ftb_a = baseRate

            // Method 2 - reduce by 30 cents over higher Income free area
            if (familyAdjustedIncome > FTB_A.HIFA) {
                ftb_a_annual = Math.max(0, ftb_a * FORTNIGHT_ANNUAL - (familyAdjustedIncome - FTB_A.HIFA) * FTB_A.HIFA_RATE)
                ftb_a = ftb_a_annual / FORTNIGHT_ANNUAL
            }

            if (maintenanceIncome_liability > 0) {
                // Maintenance Income Free Area
                // You and your partner can get a certain amount of child support and spousal maintenance before it affects your FTB. We call this the Maintenance Income Free Area. We reduce your FTB by 50 cents for every dollar of child support and spousal maintenance over this amount. We do this until you reach the base rate of FTB Part A.
                // Maintenance Income - reduce by 50 cnets over income free area, or until base is reached
                ftb_a_annual = Math.max(baseRate * FORTNIGHT_ANNUAL, ftb_a_annual - (maintenanceIncome_liability * FTB_A.MIFA_RATE))
                ftb_a = ftb_a_annual / FORTNIGHT_ANNUAL
            }

            // There are income limits where we’ll no longer pay you FTB Part A.
            if (familyAdjustedIncome >= upperLimit) ftb_a = 0


            //------------------------------
            // FTB A Supplement
            //------------------------------
            if (familyAdjustedIncome < FTB_A_SUPPLEMENT.cap && eligibleChildren > 0) {
                ftb_a_supplement = FTB_A_SUPPLEMENT.perChild * eligibleChildren
            }

            //------------------------------
            /// FTB B
            //------------------------------
            let ftbB_bracket = getBracketIncusive(youngestChild, FTB_B.maxRate);
            let ftb_b_maxRate = ftbB_bracket && ftbB_bracket.value ? ftbB_bracket.value : 0;

            ftb_b = 0;
            if (state().spouse) {
                // couple
                // age condition
                let age_test = youngestChild <= FTB_B.youngestAge.couple
                if (age_test) {
                    // income test
                    let primary = state().adjustedAnnualTaxableIncome > state().spouseIncome
                    if (primary) {
                        // Your family won’t be eligible for FTB Part B if the primary earner earns more than $104,432. If they earn less, we work out how much FTB B your family can get using the secondary earner’s income.
                        if (state().adjustedAnnualTaxableIncome <= FTB_B.incomeCap) {
                            let excess = Math.max(0, state().spouseIncome - FTB_B.partnerIncomeFreeArea)
                            let ftb_b_annual = Math.max(0, ftb_b_maxRate * FORTNIGHT_ANNUAL - excess * FTB_B.partnerIncomeFreeArea_rate)
                            ftb_b = ftb_b_annual / FORTNIGHT_ANNUAL
                        }
                    } else {
                        if (state().spouseIncome <= FTB_B.incomeCap) {
                            let excess = Math.max(0, state().adjustedAnnualTaxableIncome - FTB_B.partnerIncomeFreeArea)
                            let ftb_b_annual = Math.max(0, ftb_b_maxRate * FORTNIGHT_ANNUAL - excess * FTB_B.partnerIncomeFreeArea_rate)
                            ftb_b = ftb_b_annual / FORTNIGHT_ANNUAL
                        }
                    }
                }
            } else {
                // single - income under cap and youngest < 18
                let age_test = youngestChild <= FTB_B.youngestAge.single
                if (age_test && familyAdjustedIncome <= FTB_B.incomeCap) {
                    ftb_b = ftb_b_maxRate;
                }
            }

            //------------------------------
            // FTB B Supplement
            //------------------------------
            if (familyAdjustedIncome < FTB_B_SUPPLEMENT.cap && eligibleChildren > 0) {
                ftb_b_supplement = FTB_B_SUPPLEMENT.perFamily
            }

            //------------------------------
            // shared care ratio
            //------------------------------
            ftb_a *= careRatio / 100
            ftb_a_supplement *= careRatio / 100
            ftb_b *= careRatio / 100
            ftb_b_supplement *= careRatio / 100

            state().ftb_careRatio = careRatio;
            state().ftb_a = ftb_a;
            state().ftb_a_supplement = ftb_a_supplement;
            state().ftb_b = ftb_b;
            state().ftb_b_supplement = ftb_b_supplement;

            state().ftb = {
                a: ftb_a * fortnight2Year + ftb_a_supplement + ftb_b * fortnight2Year + ftb_b_supplement,
                m: ftb_a * fortnight2Year / month2Year + ftb_b * fortnight2Year / month2Year,
                f: ftb_a + ftb_b,
                w: ftb_a / week2Fortnight + ftb_b / week2Fortnight,
                d: ftb_a / week2Fortnight / day2Week + ftb_b / week2Fortnight / day2Week
            }
        }

        return Calculator;
    },



    ///////////////////////////////////////////
    // Gross Tax
    ///////////////////////////////////////////

    calculateGrossTax: () => {

        state().otherTaxesAndLevies = getOther(state().income.a); // this should be a negative amount

        state().otherTax.a = state().help.a + state().sfss.a + state().levies.a + state().otherTaxesAndLevies + state().superannuationExcessTax + state().division293;
        state().otherTax.m = state().help.m + state().sfss.m + state().levies.m;
        state().otherTax.f = state().help.f + state().sfss.f + state().levies.f;
        state().otherTax.w = state().help.w + state().sfss.w + state().levies.w;
        state().otherTax.d = state().help.d + state().sfss.d + state().levies.d;

        // NORMALISE TAX TABLE DATA
        // if the payg figure was using the ATO calculations - deduct medicare and other taxes
        if (state().PAYG) {
            // this needs to be taken from the taxPAYG value as the ATO tax tables include it
            // also includes levies

            if (state().incomeTax.d > 0) state().incomeTax.d -= (state().medicare.d + state().levies.d);
            if (state().incomeTax.w > 0) state().incomeTax.w -= (state().medicare.w + state().levies.w);
            if (state().incomeTax.f > 0) state().incomeTax.f -= (state().medicare.f + state().levies.f);
            if (state().incomeTax.m > 0) state().incomeTax.m -= (state().medicare.m + state().levies.m);

        }

        state().grossTax.a = state().incomeTax.a + state().extraWithholdingTax.a + state().medicare.a + state().medicareSurchargeLiability + state().otherTax.a - state().offsets.a;
        state().grossTax.m = state().incomeTax.m + state().extraWithholdingTax.m + state().medicare.m + state().otherTax.m - state().offsets.m;
        state().grossTax.f = state().incomeTax.f + state().extraWithholdingTax.f + state().medicare.f + state().otherTax.f - state().offsets.f;
        state().grossTax.w = state().incomeTax.w + state().extraWithholdingTax.w + state().medicare.w + state().otherTax.w - state().offsets.w;
        state().grossTax.d = state().incomeTax.d + state().extraWithholdingTax.d + state().medicare.d + state().otherTax.d - state().offsets.d;



        if (state().applyMedicareAdjustment) {
            state().grossTax.m -= state().medicareAdjustment.m;
            state().grossTax.f -= state().medicareAdjustment.f;
            state().grossTax.w -= state().medicareAdjustment.w;
            state().grossTax.d -= state().medicareAdjustment.d;

        }

        // Tax credits
        state().grossTax.a -= state().taxCredits;

        // Franking credits
        state().grossTax.a -= state().frankingCredits;

        // Excess Contributions offset
        state().grossTax.a -= state().superannuationExcessConcessionalContributionsOffset;

        // Business GST is not part of income tax
        // if(state().businessIncome !== 0){
        //     if(state().includeGST){
        //         state().grossTax.a += state().businessIncome*(0.1/1.1)
        //     }
        // }

        // rounding?
        const rounding = false;
        if (rounding) {
            state().grossTax.a = Math.round(state().grossTax.a);
            // state().grossTax.m = Math.round(state().grossTax.m);
            // state().grossTax.f = Math.round(state().grossTax.f);
            // state().grossTax.w = Math.round(state().grossTax.w);
        }

        if (Calculator.isDebug) console.log("Calculate Gross Tax -> grossTax: ", state().grossTax);

        return Calculator;
    },

    ///////////////////////////////////////////
    // Net income
    ///////////////////////////////////////////
    calculateNetIncome: () => {

        // sum up income, tax and include deductions
        state().net.a = state().income.a - state().grossTax.a;
        state().net.m = state().income.m - state().grossTax.m;
        state().net.f = state().income.f - state().grossTax.f;
        state().net.w = state().income.w - state().grossTax.w;
        state().net.d = state().income.d - state().grossTax.d;

        // add deduction back on, but make sure deductions < baseIncome ( deductions are negative)
        state().net.a += Math.min(state().baseSalary.a, state().deductions.a);
        state().net.m += Math.min(state().baseSalary.m, state().deductions.m || 0);
        state().net.f += Math.min(state().baseSalary.f, state().deductions.f || 0);
        state().net.w += Math.min(state().baseSalary.w, state().deductions.w || 0);
        state().net.d += Math.min(state().baseSalary.d, state().deductions.d || 0);

        if (!state().allowanceIncomeTaxable) {
            state().net.a += state().allowance.a
            state().net.m += state().allowance.m
            state().net.f += state().allowance.f
            state().net.w += state().allowance.w
            state().net.d += state().allowance.d
        }

        // if (state().adjustFringeBenefits !== false) {
        //     state().net.a += state().fringeBenefitsAnnual.a
        //     state().net.m += state().fringeBenefitsAnnual.m
        //     state().net.f += state().fringeBenefitsAnnual.f
        //     state().net.w += state().fringeBenefitsAnnual.w
        //     state().net.d += state().fringeBenefitsAnnual.d
        // }

        // remove sacraficed non concessional super from pay - this is calcualted for tax but not included in pay - it is going into the super fund
        state().net.a -= Number(state().superannuationUntaxedNonConcessional);

        // add voluntry concessional back onto net pay ( had been taken out of taxable income) 
        state().net.a += Number(state().concessionalAdditionalSuper);

        // include other income
        state().net.a += Number(state().otherIncome);

        // include bonus pay
        if (state().hasBonus) {
            state().net.a += Number(state().bonusIncome);
        }

        // // include other income
        state().net.a += state().includeGST ? Number(state().businessIncome) / 1.1 : Number(state().businessIncome);
        state().net.a -= Number(state().businessIncomeLoss)

        // include capital gains
        state().net.a += state().capitalGains > 0 ? Number(state().capitalGains) : 0

        // include allowance
        // state().net.a += state().allowance.a;

        // if(state().adjustSuperannuationCarryForward){
        //     state().net.a += Number(state().superannuationCarryForward);
        // }
        state().netIncome = state().net.a
        state().netIncome -= state().taxableDeductions
        state().netIncome -= state().adjustSuperannuation ? state().additionalSuper : 0


        // Calculate "salaryAnnual" for the purposes of generating a shorthand for the "inputted salary" used in the UI
        if (state().includesSuperannuation) {
            state().salaryAnnual = state().baseSalary.a + state().superannuation.a + state().superannuationReportable.a
        } else {
            state().salaryAnnual = state().baseSalary.a
        }

        return Calculator;
    },
}




///////////////////////////////////////////
// Income Aux
///////////////////////////////////////////

const getInputIncome = () => {
    // append any adjustment value to the input income
    return Number(state().salary) + Number(state().adjustment);
}



///////////////////////////////////////////
// Superannuation Aux
///////////////////////////////////////////

// getSuperannuation(state().baseSalary.a, true, options);
const getSuperannuation = (taxableIncome, subtractive, rate) => {
    let superannuation = 0;

    if (TaxData().superannuation) {
        // Use the bracket calculator
        let superBracket = TaxData().superannuation.brackets;

        const inc = TaxData().superannuation.incremental;
        let cap = 0;

        superBracket = [{ from: 0, to: 0, type: "percent", nearest: 1, value: rate }];
        superannuation = calculateBracket(taxableIncome, superBracket, inc, subtractive, cap);


    } else {
        console.log("No 'superannuation' data");
    }

    return superannuation;
}



///////////////////////////////////////////
// Income Tax Aux
///////////////////////////////////////////

const getIncomeTax = (overrideIncome = -1 * Number.EPSILON) => {
    let tax = 0;
    if (TaxData().tax) {
        const bracket = TaxData().tax.brackets;
        const inc = TaxData().tax.incremental;
        const income = overrideIncome >= 0 ? overrideIncome : state().income.a;
        //tax = calculateBracket(income, bracket, inc,false, 0, true);
        tax = calculateBracket(income, bracket, inc);
    } else {
        console.log("No 'tax' data")
    }
    return tax;
}


const getPAYGIncomeTax = (income, cycle) => {
    if (TaxData().tax.payg === undefined) {
        state().PAYG = false;
        return divideTaxByCycle(getIncomeTax(multiplyByCycle(income, cycle)), cycle);
    }
    state().PAYG = true;
    let tax = calculatePAYG(income, TaxData().tax.payg, cycle);

    return tax
}


const getPAYGIncomeTaxHalfMedicare = (income, cycle) => {
    if (TaxData().taxMedicareHalf === undefined || TaxData().taxMedicareHalf.payg === undefined) {
        state().PAYG = false;
        return getPAYGIncomeTax(income, cycle);
    }
    state().PAYG = true;
    let tax = calculatePAYG(income, TaxData().taxMedicareHalf.payg, cycle);
    return tax
}


const getPAYGIncomeTaxFullMedicare = (income, cycle) => {
    if (TaxData().taxMedicareFull === undefined || TaxData().taxMedicareFull.payg === undefined) {
        state().PAYG = false;
        return getPAYGIncomeTax(income, cycle);
    }
    state().PAYG = true;
    let tax = calculatePAYG(income, TaxData().taxMedicareFull.payg, cycle);
    return tax
}



const getNoTaxFreeThresholdTax = (overrideIncome = 0) => {
    let tax = 0;
    const income = overrideIncome > 0 ? overrideIncome : state().income.a;
    if (TaxData().taxNoFreeThreshold) {
        const bracket = TaxData().taxNoFreeThreshold.brackets;
        const inc = TaxData().taxNoFreeThreshold.incremental;
        tax = calculateBracket(income, bracket, inc);
    } else {
        console.log("No 'taxNoFreeThreshold' data")
    }
    return tax;
}

const getPAYGNoTaxFreeThresholdTax = (income, cycle) => {
    if (TaxData().taxNoFreeThreshold.payg === undefined) {
        state().PAYG = false;
        return divideTaxByCycle(getNoTaxFreeThresholdTax(), cycle);
    }
    state().PAYG = true;
    return calculatePAYG(income, TaxData().taxNoFreeThreshold.payg, cycle);
}

const getNonResidentTax = (overrideIncome = 0) => {
    let tax = 0;
    const income = overrideIncome > 0 ? overrideIncome : state().income.a;
    if (TaxData().taxNonResident) {
        const bracket = TaxData().taxNonResident.brackets;
        const inc = TaxData().taxNonResident.incremental;
        tax = calculateBracket(income, bracket, inc);
    } else {
        console.log("No 'taxNonResident' data")
    }
    return tax;
}

const getPAYGNonResidentTax = (income, cycle) => {
    if (TaxData().taxNonResident.payg === undefined) {
        state().PAYG = false;
        return divideTaxByCycle(getNonResidentTax(), cycle);
    }
    state().PAYG = true;
    return calculatePAYG(income, TaxData().taxNonResident.payg, cycle);
}

const getBackpackerTax = (overrideIncome = 0) => {
    let tax = 0;
    const income = overrideIncome > 0 ? overrideIncome : state().income.a;
    if (TaxData().taxBackpacker) {
        const bracket = TaxData().taxBackpacker.brackets;
        const inc = TaxData().taxBackpacker.incremental;
        tax = calculateBracket(income, bracket, inc);
    } else {
        console.log("No 'taxBackpacker' data")
    }
    return tax;
}

const getPAYGBackpackerTax = (income, cycle) => {

    // backpacker PAY can be paid as if this is the only payment per year.
    // add comment: If you have paid the Working Holiday Maker more than $37,000 in this income year the above calculation is incorrect. Please refer to the Tax Table Link opens in new window for Working Holiday Makers for instructions.
    if (TaxData().taxBackpacker.payg === undefined) {
        state().PAYG = false;
        // SPECIAL CASE !!
        // use specific bracket
        const bracket = TaxData().taxBackpacker.brackets[state().WHMIncomeOption]
        let tax = income * (Number(bracket.value) * 0.01);
        return Math.round(tax);

        //const bracket = TaxData().taxBackpacker.brackets;
        //const inc = TaxData().taxBackpacker.incremental;
        //return calculateBracket(income, bracket, inc);

        //return divideTaxByCycle(getBackpackerTax(), cycle);
    }

    state().PAYG = true;
    return calculatePAYG(income, TaxData().taxBackpacker.payg, cycle);
}



///////////////////////////////////////////
// Student Loans
///////////////////////////////////////////

export const getHELP = (taxableComponent, include, rounding) => {
    let help = 0;
    if (TaxData().help) {
        let bracket = TaxData().help.brackets;
        let inc = TaxData().help.incremental;
        if (rounding) {
            help = calculateBracketATORounding(taxableComponent, bracket, inc);
        } else {
            help = calculateBracket(taxableComponent, bracket, inc);
        }
    } else {
        console.log("No 'help' data")
    }


    return help;
}

const getHELP_noTaxFree = (taxableComponent, include, rounding) => {
    // if(!include) return 0;
    let help = 0;
    if (TaxData().help_noTaxFree) {
        let bracket = TaxData().help_noTaxFree.brackets;
        let inc = TaxData().help_noTaxFree.incremental;
        if (rounding) {
            help = calculateBracketATORounding(taxableComponent, bracket, inc);
        } else {
            help = calculateBracket(taxableComponent, bracket, inc);
        }
    } else {
        help = getHELP(taxableComponent, include, rounding);
    }
    return help;
}

// const getSFSS = (taxableComponent, include, rounding) => {
//     let sfss = 0;
//     if (TaxData().sfss) {
//         let bracket = TaxData().sfss.brackets;
//         let inc = TaxData().sfss.incremental;
//         if (rounding) {
//             sfss = calculateBracketATORounding(taxableComponent, bracket, inc);
//         } else {
//             sfss = calculateBracket(taxableComponent, bracket, inc);
//         }
//     } else {
//         console.log("No 'sfss' data")
//     }
//     return sfss;
// }

// const getSFSS_noTaxFree = (taxableComponent, include, rounding) => {
//     // if(!include) return 0;
//     let sfss = 0;
//     if (TaxData().sfss_noTaxFree) {
//         let bracket = TaxData().sfss_noTaxFree.brackets;
//         let inc = TaxData().sfss_noTaxFree.incremental;
//         if (rounding) {
//             sfss = calculateBracketATORounding(taxableComponent, bracket, inc);
//         } else {
//             sfss = calculateBracket(taxableComponent, bracket, inc);
//         }
//     } else {
//         sfss = getSFSS(taxableComponent, include, rounding);
//     }
//     return sfss;
// }



///////////////////////////////////////////
// Medicare Aux
///////////////////////////////////////////
export const getMedicare = (income, senior = false) => {
    let medicare = 0;
    let data = undefined;

    if (senior && TaxData().medicareSenior) data = TaxData().medicareSenior;
    if (!senior && TaxData().medicare) data = TaxData().medicare;

    if (!data) {
        console.log(`No 'medicare' data. ${senior ? "(senior)" : ""}`)
        return 0
    }

    medicare = calculateBracket(income, data.brackets, data.incremental, false, 0);

    // nearest cent 
    return roundToNearestCent(medicare);
}



// ref: https://www.ato.gov.au/Individuals/myTax/2020/In-detail/medicare-levy-reduction-or-exemption/?anchor=spouse

export const getMedicareFamily = (income, spouseIncome = 0, dependantsCount = 0, senior = false) => {
    let medicareData = undefined;
    if (senior && TaxData().medicareSeniorFamily) medicareData = TaxData().medicareSeniorFamily;
    if (!senior && TaxData().medicareFamily) medicareData = TaxData().medicareFamily;

    let brackets = medicareData.brackets ?? []
    const dependantsOffset = medicareData.dependants ? dependantsCount * Number(medicareData.dependants) : 0;

    if (dependantsOffset > 0) {
        // modify brackets for dependants offset - first clone the brackets then offset from,to

        brackets = medicareData.brackets.map(obj => {
            const _obj = { ...obj };
            const from = _obj.from;
            const to = _obj.to;
            _obj.from = from > 0 ? from + dependantsOffset : 0;
            _obj.to = to > 0 ? to + dependantsOffset : 0;
            return _obj
        });

        // calculate runout - blend offset 10% rate into 2% rate without a step
        const m1 = brackets[brackets.length - 1].value / 100;
        const m2 = brackets[brackets.length - 2].value / 100;
        const runout = m1 * (dependantsOffset / (m2 - m1));

        brackets[brackets.length - 1].from += runout;
        brackets[brackets.length - 2].to += runout;
    }

    const familyIncome = spouseIncome + income;
    const upperThreshold = brackets[1].to;

    // if the family income in the top bracket?
    if (familyIncome >= upperThreshold) {
        // no family benefit
        return getMedicare(income, senior);
    }

    const medicareSingleLowerThreshold = TaxData().medicare.brackets[1].from
    const medicareSingleUpperThreshold = TaxData().medicare.brackets[1].to

    const splitThreshold1 = medicareSingleUpperThreshold + 0.25 //lowerThreshold * 0.74124// 29033 //29206.25// familyIncome*0.608464;// decrease + 10% //29206
    const splitThreshold2 = familyIncome - medicareSingleLowerThreshold// lowerThreshold*0.502 //24774// 24635// familyIncome*0.513229;// fixed 24635

    let reduction = 0;
    let share = 0;
    reduction = calculateBracket(familyIncome, brackets, medicareData.incremental, false, 0);

    const baseline = getMedicare(income, senior);
    if (income < splitThreshold2) {
        share = (familyIncome - splitThreshold1) * 0.02
        share += (splitThreshold1 - splitThreshold2) * 0.1
        let m = -0.5 / (familyIncome * 0.5 - splitThreshold2)
        let c = 1 - (m * splitThreshold2)
        let shareRatio = m * income + c;
        let rem = reduction - share
        share += rem * (1.0 - shareRatio)
    }
    else if (income < splitThreshold1) {
        share = (familyIncome - splitThreshold1) * 0.02
        share += (splitThreshold1 - income) * 0.1
    } else {
        reduction = calculateBracket(familyIncome, brackets, medicareData.incremental, false, 0);
        share = spouseIncome * 0.02
    }

    const reductionSplit = reduction - share
    // use lower of baseline or reduced family split
    return Math.min(baseline, reductionSplit);
}



// The Medicare levy is also shaded in for scale 6. The Medicare levy parameters for scales 2 and 6 are as follows:

export const getMedicareAdjustment = (taxableComponent, dependantsCount, spouse, spouseIncomeWeekly, scale) => {

    // Only applied to families
    if (!spouse && dependantsCount === 0) return 0;


    let adjustment = 0;
    const medicareData = TaxData().medicareAdjustment;

    // Scale 2 - Regular tax payer
    // Scale 6 - Claiming Half medicare

    //let scale = "scale2" //"scale6";

    //const earningThreshold = medicareData.earningThreshold[scale];
    const shadeInThreshold = medicareData.shadeInThreshold[scale];
    const annualThreshold = medicareData.annualThreshold[scale];
    const additioalChild = medicareData.additioalChild[scale];
    const shadeOutMultiplier = medicareData.shadeOutMultiplier[scale];
    const shadeOutDivisor = medicareData.shadeOutDivisor[scale];
    const weeklyAdjustment = medicareData.weeklyAdjustment[scale];
    const medicareLevy = medicareData.medicareLevy[scale];

    if (dependantsCount >= 1) {
        adjustment = dependantsCount * additioalChild;
    }

    //let startThreshold = Number(medicareData.brackets[1].from);
    let spouseIncome = spouseIncomeWeekly * 52;
    //let assesableIncome = taxableComponent + (spouseIncome) * 0.8 - adjustment;
    let familyIncome = spouseIncome + taxableComponent;


    let weekly = Math.floor(familyIncome / 52) + 0.99;

    let WFT = (adjustment + annualThreshold) / 52;
    WFT = Math.round(WFT * 100) / 100;

    let SOP = Math.floor((WFT * shadeOutMultiplier) / shadeOutDivisor);

    let WLA = 0
    //if( weekly > earningThreshold  && weekly < SOP){
    if (weekly < shadeInThreshold) {
        WLA = (weekly - weeklyAdjustment) * shadeOutMultiplier;
    }
    else if (weekly >= shadeInThreshold && weekly < WFT) {
        WLA = weekly * medicareLevy;
    }
    else if (weekly >= WFT && weekly < SOP) {
        WLA = (WFT * medicareLevy) - ((weekly - WFT) * shadeOutDivisor);
    }
    // }
    // WLA = (WFT * medicareLevy) - (( weekly - WFT) * shadeOutDivisor);
    WLA = Math.round(WLA);

    return WLA;
}




const getMedicareSurchargeSingle = (taxableComponent, liabilityIncome) => {
    let medicare_surcharge = 0;
    if (TaxData().medicareSurcharge) {
        let bracket = TaxData().medicareSurcharge.brackets;
        let thresholdBracket = getBracket(taxableComponent, bracket);
        if (!thresholdBracket) return 0;
        switch (thresholdBracket.type) {
            case "percent":
                medicare_surcharge = liabilityIncome * (Number(thresholdBracket.value) / 100);
                break;
            case "fixed":
                medicare_surcharge = Number(thresholdBracket.value)
                break;
            case "rate":
            default:
                console.log("Invalid Medicare Surcharge calcualtion")
                medicare_surcharge = 0;
        }
    } else {
        console.log("No 'medicareSurcharge' data")
    }
    return medicare_surcharge;
}

const getMedicareSurchargeFamily = (familyIncome, taxableIncome, dependantsCount = 0) => {
    let medicare_surcharge = 0;
    if (TaxData().medicareSurchargeFamily) {

        let bracket = TaxData().medicareSurchargeFamily.brackets;
        // Retrieve the bracket based on the family income but perform the calcuation on the taxable income.
        // include the dependant discount for > 1 dependants
        let dependantDiscount = TaxData().medicareSurchargeFamily.dependants ? Number(TaxData().medicareSurchargeFamily.dependants) : 0;
        let thresholdAdjust = Math.max(0, (dependantsCount - 1) * dependantDiscount);
        let thresholdBracket = getBracket(familyIncome, bracket, thresholdAdjust);
        if (!thresholdBracket) return 0;

        switch (thresholdBracket.type) {
            case "percent":
                medicare_surcharge = taxableIncome * (Number(thresholdBracket.value) / 100);
                break;
            case "fixed":
                medicare_surcharge = Number(thresholdBracket.value)
                break;
            case "rate":
            default:
                console.log("Invalid Medicare Surcharge calcualtion")
                medicare_surcharge = 0;
        }
    } else {
        console.log("No 'medicareSurchargeFamily' data")
    }
    return medicare_surcharge;
}




///////////////////////////////////////////
// Offsets (Aux)
///////////////////////////////////////////
const getLITO = (taxableComponent) => {
    let offset = 0;

    if (TaxData().lito) {
        let bracket = TaxData().lito.brackets;
        let inc = TaxData().lito.incremental;
        offset = calculateBracket(taxableComponent, bracket, inc, false);

        if (offset < 0) offset = 0;
        if ((taxableComponent - offset) < 0) { offset = 1 * taxableComponent } // offset cannot be less than incomeTax
        //offset  = offset > 0 ? -1*offset : 0;
    } else {
        console.log("No 'lito' data")
    }
    return offset;
}

const getLAMITO = (taxableComponent) => {
    // does it apply?
    let offset = 0;
    if (TaxData().lamito) {
        let bracket = TaxData().lamito.brackets;
        let inc = TaxData().lamito.incremental;
        //function calculateBracket(v, b, incremental, subtractive, cap, debug){
        offset = calculateBracket(taxableComponent, bracket, inc, false);

        if (offset < 0) offset = 0;
        if ((taxableComponent - offset) < 0) { offset = 1 * taxableComponent } // offset cannot be less than incomeTax
        //offset  = offset > 0 ? -1*offset : 0;
    } else {
        console.log("No 'lamito' data")
    }


    // 2022 Cost of Living Tax offset
    let colto = 0;
    if (TaxData().colto) {
        let bracket = TaxData().colto.brackets;
        let inc = TaxData().colto.incremental;
        colto = calculateBracket(taxableComponent, bracket, inc, false);
        if (colto < 0) colto = 0;
    }

    // add colto to lamito
    offset += colto;
    if ((taxableComponent - offset) < 0) { offset = 1 * taxableComponent } // offset cannot be less than incomeTax

    return offset;
}

// const getMITO = (incomeTax) => {
//     let offset = 0;
//     if (TaxData().mito) {
//         let bracket = TaxData().mito.brackets;
//         let inc = TaxData().mito.incremental;
//         offset = -1 * calculateBracket(incomeTax, bracket, inc, false);
//         if (offset > 0) offset = 0;
//         if ((incomeTax + offset) < 0) { offset = -1 * incomeTax } // offset cannot be less than incomeTax
//         return offset;
//     } else {
//         console.log("No 'mito' data")
//     }
//     return 0;
// }



const getSAPTO = (rebateIncome, married, separated, spouseIncome) => {
    let offset = 0;

    if (TaxData().sapto) {

        let bracket = TaxData().sapto ? TaxData().sapto : 0;
        let inc = TaxData().sapto.incremental;
        let income = rebateIncome;

        if (!married && TaxData().sapto.single) {
            bracket = TaxData().sapto.single.brackets;
        }

        // half (50%) of their combined rebate incomes is above the cut-off threshold of $41,790.

        if (married && !separated && TaxData().sapto.married) {
            income = (rebateIncome + spouseIncome)*0.5
            bracket = TaxData().sapto.married.brackets;
        }

        if (married && separated && TaxData().sapto.illness) {
            income = (rebateIncome + spouseIncome)*0.5
            bracket = TaxData().sapto.illness.brackets;
        }

        offset = calculateBracket(income, bracket, inc, false, false);

        if (offset < 0) offset = 0;
        if ((income - offset) < 0) { offset = 1 * income } // offset cannot be less than incomeTax
        return offset;
    } else {
        console.log("NO 'sapto'' data!!")
    }

    return 0;
}




// const getMAWTO = (incomeTax) => {
//     let offset = 0;
//     if (TaxData().mawto) {
//         let bracket = TaxData().mawto.brackets;
//         let inc = TaxData().mawto.incremental;

//         offset = calculateBracket(incomeTax, bracket, inc, false, 0);
//         if (offset > 0) offset = 0;
//         if ((incomeTax + offset) < 0) { offset = -1 * incomeTax } // offset cannot be less than incomeTax
//     } else {
//         console.log("No 'mawto' data")
//     }
//     return offset;
// }

const getOther = (incomeTax) => {
    let offset = 0;
    if (TaxData().other) {
        for (let i = 0; i < TaxData().other.length; i++) {
            let bracket = TaxData().other[i].brackets;
            let inc = TaxData().other[i].incremental;
            offset += calculateBracket(incomeTax, bracket, inc, false);
        }
    } else {
        console.log("No 'other' data")
    }
    return offset;
}


const getSpouseSuperTaxOffset = (spouseIncome, spouseContributions) => {
    let taxOffset = 0;

    if (TaxData().superannuationSpouseTaxOffset) {
        const bracket = TaxData().superannuationSpouseTaxOffset.brackets;
        const inc = TaxData().superannuationSpouseTaxOffset.incremental;
        const rate = Number(TaxData().superannuationSpouseTaxOffset.rate);
        const spouseOffset = calculateBracket(spouseIncome, bracket, inc);
        taxOffset = Math.min(spouseContributions * rate, spouseOffset * rate);

    } else {
        console.log("No 'superannuationSpouseTaxOffset' data")
    }
    return taxOffset;
}





///////////////////////////////////////////
// Auxillery
///////////////////////////////////////////

export const zero = (obj) => {
    obj.a = CALCULATION_OBJECT.a;
    obj.m = CALCULATION_OBJECT.m;
    obj.f = CALCULATION_OBJECT.f;
    obj.w = CALCULATION_OBJECT.w;
    obj.d = CALCULATION_OBJECT.d;
    // obj = Object.assign(CALCULATION_OBJECT);
}

// get rid of negaive zeros
// -0 and +0 are stupid. Let 0 be 0
const cleanZeros = (obj) => {
    Object.keys(obj).map(k => {
        if (obj[k] === -0) obj[k] = 0;
        return true;
    })
}

const divideTaxByCycle = (tax, cycle) => {
    switch (cycle) {
        case DAILY:
            return tax / day2Year;
        case WEEKLY:
            return tax / week2Year;
        case FORTNIGHTLY:
            return tax / fortnight2Year;
        case MONTHLY:
            return tax / month2Year;
        default:
            break;
    }
    return tax;
}


const multiplyByCycle = (value, cycle) => {
    switch (cycle) {
        case DAILY:
            return value * day2Year;
        case WEEKLY:
            return value * week2Year;
        case FORTNIGHTLY:
            return value * fortnight2Year;
        case MONTHLY:
            return value * month2Year;
        default:
            break;
    }
    return value;
}


// Take an annual value object and spread the annual figure to w,f and m
export const spreadAnnualAmounts = (obj) => {
    obj.m = obj.a / month2Year;
    obj.f = obj.a / fortnight2Year;
    obj.w = obj.a / week2Year;
    obj.d = obj.w / day2Week;
    return obj;
}


// Take a single annual value and return an annual value object
export const spreadAnnualValue = (val) => {
    return spreadAnnualAmounts({ a: val })
}


///////////////////////////////////////////
// PAYG
///////////////////////////////////////////
export const calculatePAYG = (income, paygBrackets, cycle) => {

    if (isUndefined(paygBrackets)) return 0
    // reduce income to weekly
    let paygIncome;
    switch (cycle) {
        case MONTHLY:
            // "if the result is an amount ending in 33 cents, add one cent"
            let cents = Math.round(100 * (income - Math.floor(income)));
            if (cents === 33) income += 0.01;
            paygIncome = Math.floor((income * 3) / 13);
            paygIncome += 0.99;
            break;

        case FORTNIGHTLY:
            paygIncome = income / 2;
            paygIncome = Math.floor(paygIncome);
            paygIncome += 0.99;
            break;

        case DAILY:
            // convery daily  income to weekly
            paygIncome = income * (day2Year / week2Year)
            paygIncome += 0.99;
            break;

        case WEEKLY:
        default:
            paygIncome = Math.floor(income);
            paygIncome += 0.99;
            break;

    }

    let a = 0;
    let b = 0;
    // find bracket

    for (let i = 0; i < paygBrackets.length; i++) {
        if (paygIncome < paygBrackets[i].income || paygBrackets[i].income === 0) {
            a = paygBrackets[i].a;
            b = paygBrackets[i].b;
            break;
        }
    }

    let tax = paygIncome * a - b;
    tax = Math.round(tax);

    //convert back to cycle
    switch (cycle) {
        case MONTHLY:
            tax = (tax * 13) / 3
            tax = Math.round(tax);
            break;
        case FORTNIGHTLY:
            tax = tax * 2;
            break;
        case DAILY:
            tax = tax / (day2Year / week2Year);
            tax = Math.round(tax);
            break;
        case WEEKLY:
        default:
            break;
    }

    return tax;
}



///////////////////////////////////////////
// Bracket calculations
///////////////////////////////////////////

// Unntuative behaviour!
// from > value <= to
// 0 - 10, valid for 1 - 10 
// threshold adjust + 1
// 0 - 10 valid 2 > 11


export const getBracket = (v, b, thresholdAdjust = 0, debug = false) => {

    if (debug) {
        console.log("get bracket:", v, b)
    }

    if (v === undefined || b === undefined) return false;
    let bracket = b[0]
    for (let i = 0; i < b.length; i++) {
        let from = Number(b[i].from) + Number(thresholdAdjust)
        let to = Number(b[i].to) + Number(thresholdAdjust)
        if (debug) console.log("check:", from, v, to)

        if (v > from || (v === 0 && from === 0)) {
            if (v <= to || Number(b[i].to) === 0) {
                if (debug) console.log("set bracket", b[i])
                bracket = b[i];
            }
        }
    }
    return bracket;
}



// getBracketInclusive
// from >= value <= to
// 0 - 10, valid for 0 - 10 

export const getBracketIncusive = (v, b, debug = false) => {

    if (debug) {
        console.log("get bracket:", v, b)
    }

    if (v === undefined || b === undefined) return false;
    let bracket = b[0]
    for (let i = 0; i < b.length; i++) {
        let from = Number(b[i].from)
        let to = Number(b[i].to)
        if (v >= from || (v === 0 && from === 0)) {
            if (v <= to || Number(b[i].to) === 0) {
                bracket = b[i];
            }
        }
    }
    return bracket;
}


export const calculateBracket = (v, b, incremental, subtractive, cap, debug) => {
    // round to the nearest cent
    return calculateBracketWithRounding(v, b, incremental, subtractive, cap, debug, 0.01);
}

const calculateBracketATORounding = (v, b, incremental, subtractive, cap, debug) => {
    // round to the nearest week
    return calculateBracketWithRounding(v, b, incremental, subtractive, cap, debug, 0.52);
}

const calculateBracketWithRounding = (v, b, incremental, subtractive, cap, debug, rounding) => {
    if (isUndefined(b)) return 0
    let r = 0;
    let inc = (incremental === 1) ? true : false;

    rounding = rounding || 0.01;
    // v for value
    // b for brackets
    for (let i = 0; i < b.length; i++) {
        // for each of the brackets
        let from = Number(b[i].from) || 0;
        let to = Number(b[i].to) || 0;
        let nearest = Number(b[i].nearest) || 1;
        let val = Number(b[i].value) || 0;
        let start = Number(b[i].start) || 0;
        let end = Number(b[i].end) || 0;
        let bracketAmount;
        let type = b[i].type;

        if (debug) console.log("bracket: from:" + from + " to: " + to + " amount: " + val + " bracket value: " + v, " type:", type);

        if (b[i].incremental !== undefined) {
            // this bracket has an incremental override (medicare)
            if (debug) console.log("incremental bracket! ");
            inc = (b[i].incremental === 1 || b[i].incremental === "true") ? true : false;
        }

        // trigger on active bracket
        if (v >= from || (from === 0 && v === 0)) {

            // part bracket > from and < to, otherwise it is complete bracket
            let partBracket = (v <= to || to === 0);

            // calculate the value within this bracket (check cap)
            if (partBracket) {
                // bracketAmount = (Math.ceil((v - from) / nearest)) * nearest;
                bracketAmount = (((v - from) / nearest)) * nearest;
            } else {
                bracketAmount = (Math.ceil((to - from) / nearest)) * nearest;
            }




            // if not incremental only concern is the final brackets
            if (!inc && !partBracket) continue;

            if (debug) console.log("Current bracket... partBracket:", partBracket, " type: " + b[i].type + " index:" + i + " from:" + from + " to:" + to + " val: " + v, "  part: ", partBracket, "  inc: ", inc, " -> r:", r);


            switch (type) {
                case "fixed":
                    r = inc ? r + val : val; // add value of fixed component
                    //if (debug) console.log("fixed bracket: ", r);
                    break;

                case "rate":
                    if (debug) console.log("RATE calculation:", start, bracketAmount, val, end, " : ", start + (bracketAmount * val / 100));

                    if (debug) console.log("RATE calculation:", start, " + (" + bracketAmount, " * ", val, "/100) = ", start, " + " + (bracketAmount * val / 100));


                    let rateValue = start + (bracketAmount * val / 100);
                    if (rateValue > end && val > 0) rateValue = end; // upper limit on improving rate
                    if (rateValue < end && val < 0) rateValue = end; // lower limit on decending rate
                    r = inc ? r + rateValue : rateValue;
                    //if (debug) console.log("rate bracket: ", r);
                    break;

                case "percent":
                    if (partBracket) {
                        //if (debug) console.log("part bracket: ", partBracket);

                        // part bracket
                        // if the brackets are incremental take the percentage from the individual bracket
                        // otherwise take a percentage from the total value
                        if (inc) {
                            //if (debug) console.log("include bracket... ");
                            // apply cap - (superannuation)
                            if (bracketAmount > cap && cap > 0) bracketAmount = cap;
                            //if (debug) console.log("include bracket... " + bracketAmount + " val: " + val);
                            // ATO rounding
                            // let percentValue = rounding * Math.round((bracketAmount * (val / 100)) / rounding);
                            let percentValue = ((bracketAmount * (val / 100)));
                            // subtractive? cap?
                            r += percentValue;
                            if (debug) console.log("include bracket... = ", percentValue, r);

                        } else {
                            // take the full amount not just the partial bracket value
                            let percentValue = rounding * Math.round((v * (val / 100)) / rounding);
                            // this is a superannuation option
                            if (subtractive) { percentValue /= (1 + (val / 100)) }

                            // check cap (superannuation)
                            if (percentValue > cap && cap > 0) { percentValue = cap }

                            r = percentValue;
                            if (debug) console.log("Bracket amount (non inc) " + r);
                        }
                    } else {
                        //if (debug) console.log("Full bracket: ", partBracket, " inc: ", inc);
                        if (inc) {

                            if (bracketAmount > cap && cap > 0) bracketAmount = cap;
                            let percentValue = rounding * Math.round((bracketAmount * (val / 100)) / rounding);

                            if (r > cap && cap > 0) r = cap;
                            if (subtractive) { r /= (1 + (val / 100)); }
                            r += percentValue;
                            if (debug) console.log("add full bracket... bracketAmount: ", bracketAmount, percentValue, r);
                        }
                    }
                    break;
                default:
                    break;
            }
        }
    }
    if (debug) console.log("final amount: ", r, Math.round(r * 100) / 100);
    return Math.round(r * 100) / 100;
}



///////////////////////////////////////////
// Modify Brackets
///////////////////////////////////////////
export const increaseBrackets = (brackets, increase) => {

    // go through all the thresholds and multiply be 'increase'

    return brackets.map(b => {
        return { ...b, from: b.from * increase, to: b.to * increase };
    })


}